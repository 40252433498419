const CardData7 = [
  {
    title: 'Mana',
    description:
      'Maná es una banda de rock pop mexicana fundada en 1986 en Guadalajara, Jalisco. Actualmente la integran Fher Olvera, Juan Calleros, Álex González y Sergio Vallín. Ha vendido más de 40 millones de discos en todo el mundo..',
    videourl: 'https://youtube.com/playlist?list=UU3cSX-0y3IZzr2F2J1dFlUg',
    price: '$50',
    coverImg: 'Mana.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.mana.com.mx/',
    youtube: 'https://www.youtube.com/channel/UC3cSX-0y3IZzr2F2J1dFlUg',
    facebook: 'https://www.facebook.com/mana',
    instagram: 'https://www.instagram.com/manaoficial/',
    twitter: 'https://twitter.com/search?q=Mana&src=typed_query',
    spotify: 'https://open.spotify.com/artist/7okwEbXzyT2VffBmyQBWLz',
    deezer: 'https://www.deezer.com/mx/artist/4115',
    apple: 'https://music.apple.com/mx/artist/man%C3%A1/89034',
    soundcloud: 'https://soundcloud.com/mana-oficial',
    tidal: 'https://listen.tidal.com/artist/12180',
    wiki: 'https://es.wikipedia.org/wiki/Man%C3%A1_(banda)',
    ticketmaster: 'https://www.ticketmaster.com.mx/mana-boletos/artist/759223',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=mana%20canciones&t=1654465656447',
    napster: 'https://app.napster.com/artist/mana',
  },{
    title: 'Morat',
    description:
      'Morat es una banda colombiana de rock pop latino, formada originalmente en Bogotá el 13 de diciembre del 2011. Se dieron a conocer en 2015 con su canción «Mi Nuevo Vicio». Sus integrantes son Juan Pablo Isaza Piñeros, Juan Pablo Villamil Cortés, Simón Vargas Morales y Martín Vargas Morales. Los miembros originales de Morat se conocieron mientras estudiaban en el Gimnasio La Montaña, un colegio privado en Bogotá, Colombia. Desde temprana edad, compartieron una afinidad por la música, participando en actividades escolares relacionadas con arte y presentaciones musicales. Fue en este entorno donde comenzaron a formar una conexión no solo como amigos, sino también como futuros compañeros de banda.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lykILLG-k050_d2WD-mlPLf3UlKL2g6uk&si=5q1bW3kEj5f3a3ts',
    price: '$50',
    coverImg: 'morat.jpg',
    category: 'Rock',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://morat.co/',
    youtube: 'https://www.youtube.com/channel/UCvQwfdlOR55OO82gFWJCnWQ',
    facebook: 'https://www.facebook.com/moratoficial',
    instagram: 'https://www.instagram.com/morat/?hl=en',
    twitter: 'https://x.com/MoratBanda',
    spotify: 'https://open.spotify.com/artist/5C4PDR4LnhZTbVnKWXuDKD',
    deezer: 'https://www.deezer.com/mx/artist/7327640',
    apple: 'https://music.apple.com/mx/artist/morat/955657302',
    soundcloud: 'https://soundcloud.com/morat-official',
    tidal: 'https://listen.tidal.com/artist/6414097',
    wiki: 'https://es.wikipedia.org/wiki/Morat',
    ticketmaster:
      'https://www.ticketmaster.com.mx/morat-boletos/artist/2261438',
    tiktok: 'https://www.tiktok.com/@moratbanda?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00S1N0CUQ/morat',
  },
  {
    title: 'La La Love You',
    description:
      'La La Love You es un grupo de música pop punk originario de Parla, Madrid.1​ Sus miembros son Roberto Amor (voz y guitarra), David Merino (voz y guitarra), Lydia Carré (coros y bajo) y Oscar Hoyos (batería). El 15 de marzo de 2023 se anunció que Lydia Carré sustituiría a Celia Becks en los coros y bajo tras su salida del grupo. Han publicado tres discos: Umm... Que rico! (2009), La La Love You (2013) y Blockbuster (2023). También han publicado varios singles, entre ellos «El fin del mundo» (2019), que alcanzó el triple Disco de Platino.',
    videourl:
      'https://youtube.com/playlist?list=PLINd7rN0EifTOCuipjtIkxb4Nj0JYZPNT&si=O5HhVqnsqa0z3_id',
    price: '$50',
    coverImg: 'lalaloveyou.jpg',
    category: 'Rock',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://lalaloveyou.es/',
    youtube: 'https://www.youtube.com/@subterfugerecords',
    facebook: 'https://www.facebook.com/lalaloveyouoficial',
    instagram: 'https://www.facebook.com/lalaloveyouoficial',
    twitter: 'https://x.com/_lalaloveyou_',
    spotify: 'https://open.spotify.com/artist/5Q30fhTc0Sl0Q4C5dOjhhQ',
    deezer: 'https://www.deezer.com/mx/artist/357313',
    apple: 'https://music.apple.com/mx/artist/la-la-love-you/328453221',
    soundcloud: 'https://soundcloud.com/vincentvalera',
    tidal: 'https://listen.tidal.com/artist/4650791',
    wiki: 'https://es.wikipedia.org/wiki/La_La_Love_You',
    ticketmaster:
      'https://www.ticketmaster.com.mx/la-la-love-you-boletos/artist/3093122',
    tiktok: 'https://www.tiktok.com/@lalaloveyouoficial?lang=es',
    napster: 'https://music.amazon.com.mx/search/lalaloveyou?filter=IsLibrary%7Cfalse&sc=none',
  },
  {
    title: 'Los Fabulosos Cadillacs',
    description:
      'Los Fabulosos Cadillacs es una banda argentina de ska proveniente de Buenos Aires y fundada en 1984. Es considerada una de las mejores bandas de la historia de ese género en Latinoamérica. Llevan grabados 16 álbumes y a lo largo de sus distintas eras colaboraron con distintos artistas argentinos e internacionales, obteniendo en el medio un gran reconocimiento crítico y comercial. Varios de sus trabajos han sido incluidos en listas de mejores álbumes de rock latinoamericano y han recibido nominaciones y premaciones de MTV Latinoamérica, Premios Gardel, Fundación Konex y Grammy..',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_mb7BtKgDmwJxfVeUV8bchdmIP1qgfvcKU',
    price: '$50',
    coverImg: 'LosFabulososCadillacs.jpg',
    category: 'Rock',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.fabulosos-cadillacs.com/',
    youtube: 'https://www.youtube.com/channel/UCS7dfokaYTjV6M-cLJEW5RA',
    facebook: 'https://www.facebook.com/losfabulososcadillacs/',
    instagram: 'https://www.instagram.com/lfcoficial/',
    twitter: 'https://twitter.com/lfcoficial',
    spotify: 'https://open.spotify.com/artist/2FS22haX3FYbyOsUAkuYqZ',
    deezer: 'https://www.deezer.com/mx/artist/3491',
    apple: 'https://music.apple.com/mx/artist/los-fabulosos-cadillacs/300251',
    soundcloud: 'https://soundcloud.com/losfabulososcadillacs',
    tidal: 'https://listen.tidal.com/artist/2641',
    wiki: 'https://es.wikipedia.org/wiki/Los_Fabulosos_Cadillacs',
    ticketmaster:
      'https://www.ticketmaster.com.mx/search?q=Los+Fabulosos+Cadillacs',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Los%20Fabulosos%20Cadillacs%20Canciones&t=1654467154219',
    napster: 'https://app.napster.com/artist/los-fabulosos-cadillacs',
  },
  {
    title: 'Mœnia',
    description:
      'Moenia (estilizado Mœnia) es un grupo mexicano de Synth-Pop en 1996 lanzarían su primer álbum oficial homónimo y dándose a conocer por su gran éxito No puedo estar sin ti, recibiendo numerosas aceptaciones en la industria de la música y el público en general. Juan Carlos Lozano abandonaría la agrupación después del éxito de su primer álbum. Optaron por regresar a sus orígenes con reintegrar a Alfonso Pichardo como vocalista principal. En 1999 lanzan su segundo álbum titulado Adición+ con Alfonso Pichardo como vocalista permanente de la banda.',
    videourl:
      'https://youtube.com/playlist?list=PLLOW3tRoU2ErRdFcwOaft4i735ZBeQUZk',
    price: '$50',
    coverImg: 'moenia.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.moenia.com/',
    youtube: 'https://www.youtube.com/channel/UCEJr7n8Brn1zj0ZuWZPPajw',
    facebook: 'https://www.facebook.com/moeniamx',
    instagram: 'https://www.instagram.com/moeniamx/',
    twitter: 'https://twitter.com/MoeniaMx',
    spotify: 'https://open.spotify.com/artist/3QmmtMrEf7aQrsd1VtejAV',
    deezer: 'https://www.deezer.com/mx/artist/12043',
    apple: 'https://music.apple.com/mx/artist/moenia/112531',
    soundcloud: 'https://soundcloud.com/moenia-official',
    tidal: 'https://listen.tidal.com/artist/7719',
    wiki: 'https://en.wikipedia.org/wiki/M%C5%93nia',
    ticketmaster:
      'https://www.ticketmaster.com.mx/moenia-boletos/artist/794979',
    tiktok: 'https://www.tiktok.com/@moeniamx',
    napster: 'https://music.amazon.com.mx/artists/B000TE048U/moenia',
  },
  {
    title: 'Fobia',
    description:
      'La banda inició su trayectoria en la Ciudad de México en 1987, época en la que en México había poca difusión para los grupos de rock. La historia que ellos tienen acerca de la formación de la banda se remonta a un concierto de Las Insólitas Imágenes de Aurora en 1987, De Lozanne rompe la llave de su automóvil y pidió un "ride" a Huidobro, con quien platica sobre sus inquietudes musicales y juntos unieron sus talentos y forman una agrupación. Más tarde, De Lozanne le recomendó a Huidobro, un bajista al que consideraba muy bueno.',
    videourl:
      'https://youtube.com/playlist?list=PLoaJsUNpHzl3xJfuLbQ_AMaABXH8YBB5r',
    price: '$50',
    coverImg: 'Fobia.jpeg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.fobiamx.com/?fbclid=IwAR0K3-0GZ2SyKeYFn7jVaaRGZtZe6N_P9yo1UxnMP5qHRfa86-F7JhxJsk8',
    youtube: 'https://www.youtube.com/channel/UCs1r5JVyNLXHSs2UAlPV7KA',
    facebook: 'https://www.facebook.com/fobia30',
    instagram: 'https://www.instagram.com/fobiamx/?hl=es-la',
    twitter: 'https://twitter.com/fobiamx?lang=es',
    spotify: 'https://open.spotify.com/artist/3SqzxvGCKGJ9PYKXXPwjQS',
    deezer: 'https://www.deezer.com/mx/artist/14468',
    apple: 'https://music.apple.com/mx/artist/fobia/3297308',
    soundcloud: 'https://soundcloud.com/fobia-official',
    tidal: 'https://listen.tidal.com/artist/3642162',
    wiki: 'https://es.wikipedia.org/wiki/Fobia',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Fobia',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=fobia%20canciones&t=1654467860387',
    napster: 'https://app.napster.com/artist/fobia',
  },
  {
    title: ' Lila Downs',
    description:
      'Ana Lila Downs Sánchez, más conocida como Lila Downs (Heroica Ciudad de Tlaxiaco, Oaxaca, 19 de septiembre de 1968),​ es una cantante, compositora, productora, actriz y antropóloga​ mexicana. Además de cantar en español e inglés, también interpreta melodías en diversos idiomas nativos de su país, como mixteco y zapoteco, así como maya, purépecha y náhuatl. Reivindica sus raíces mexicanas y de los pueblos originarios, además de la música regional de Oaxaca.',
    videourl: 'https://youtube.com/playlist?list=PLB41351AC75255C71',
    price: '$50',
    coverImg: 'lilaDowns.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.liladowns.com/',
    youtube: 'https://www.youtube.com/c/LilaDownsOficial',
    facebook: 'https://www.facebook.com/LilaDownsOficial',
    instagram: 'https://www.instagram.com/liladowns/',
    twitter: 'https://twitter.com/liladowns',
    spotify: 'https://open.spotify.com/artist/3mXI2gpwWnNO9qbQG3n3EP',
    deezer: 'https://www.deezer.com/mx/artist/1525',
    apple: 'https://music.apple.com/mx/artist/lila-downs/653627',
    soundcloud: 'https://soundcloud.com/lila-downs',
    tidal: 'https://listen.tidal.com/artist/3504595',
    wiki: 'https://es.wikipedia.org/wiki/Lila_Downs',
    ticketmaster:
      'https://web.superboletos.com/SuperBoletos/JALISCO/CONCIERTO/LILA-DOWNS/McSSRJaflXG8t4x6oDEV2rBsw',
    tiktok: 'https://www.tiktok.com/@liladownsmx?lang=es',
    napster: 'https://us.napster.com/artist/lila-downs',
  },
  {
    title: 'Vaquero Negro',
    description:
      'Vaquero Negro es una propuesta musical fresca que surge en Jalisco por alla de 2015 en Guadalajara. La banda está compuesta por: Nacho (vocal), Nachin (batería), (guitarra) y (bajo). Su sonido resulta de la mezcla el rock, rap, hip-hop y el nü metal, que resuena con gran fuerza en cada canción..',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUjvApMMpZQgv1-JCyuuSjZ',
    price: 80,
    coverImg: 'vaqueronegro.jpg',
    category: 'Rapcore',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://vaqueronegro.com/',
    youtube: 'https://www.youtube.com/c/VaqueroNegro',
    facebook: 'https://www.facebook.com/vaqueronegromusic',
    instagram: 'https://www.instagram.com/vaqueronegro/',
    twitter: 'https://twitter.com/vaquero_negro',
    spotify: 'https://open.spotify.com/artist/4IsUhd2aPelcKtiOJMc1q8',
    deezer: 'https://www.deezer.com/search/vaquero%20negro',
    apple: 'https://music.apple.com/us/artist/vaquero-negro/1031983109',
    soundcloud: 'https://soundcloud.com/vaqueronegro',
    tidal: 'https://listen.tidal.com/artist/7170982',
    wiki:
      'https://www.milenio.com/cultura/laberinto/raprock-vaquero-negro-hara-temblar-foro-hilvana',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Rock/Vaquero-Negro-Boletos',
    tiktok: 'https://www.tiktok.com/@vaqueronegro_oficial?lang=es',
    napster: 'https://us.napster.com/search?query=Vaquero%20Negro',
  },
  {
    title: 'Soda Stereo',
    description:
      'Soda Stereo es una banda argentina de rock formada originalmente en Belgrano, Buenos Aires en 1982 por Gustavo Cerati (voz, guitarra), Héctor «Zeta» Bosio (bajo) y Carlos Alberto Ficicchia «Charly Alberti» (batería). Es considerada por la crítica especializada como una de las más importantes, populares e influyentes bandas de rock en español de todos los tiempos y una leyenda de la música latina. Fueron el primer grupo de habla hispana en conseguir un éxito masivo en Latinoamérica y tuvieron un papel muy importante en el desarrollo y la difusión del rock iberoamericano y el rock en español durante las décadas de 1980 y 1990. Durante su carrera, fueron vanguardistas y marcaron tendencia en Latinoamérica, en la que protagonizaron diversos géneros como la música divertida de sus inicios, la new wave, el dark, el hard rock, el rock alternativo y el rock electrónico de sus finales.',
    videourl:
      'http://youtube.com/playlist?list=PLMoz13LLcTU8UdhM79EEXTsUyW8CoVb4o',
    price: 80,
    coverImg: 'sodastereo.gif',
    category: 'Rock',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://sodastereo.com/',
    youtube: 'https://www.youtube.com/c/sodastereo',
    facebook: 'https://www.facebook.com/sodastereo/',
    instagram: 'https://www.instagram.com/sodastereo/',
    twitter: 'https://twitter.com/sodastereo',
    spotify:
      'https://open.spotify.com/artist/7An4yvF7hDYDolN4m5zKBp?si=-hECX_d3Rxa26_DwGK3OaA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/4345',
    apple: 'https://music.apple.com/mx/artist/soda-stereo/302907',
    soundcloud: 'https://soundcloud.com/sodastereoofficial',
    tidal: 'https://listen.tidal.com/artist/3633734',
    wiki: 'https://es.wikipedia.org/wiki/Soda_Stereo',
    ticketmaster:
      'https://www.ticketmaster.com/soda-stereo-tickets/artist/1138263',
    tiktok: 'https://www.tiktok.com/@sodastereo?lang=es',
    napster: 'https://music.amazon.com/artists/B00137CGWE/soda-stereo',
  },
  {
    title: 'Gustavo Cerati',
    description:
      'Gustavo Adrián Cerati (Buenos Aires, 11 de agosto de 1959-Buenos Aires, 4 de septiembre de 2014), conocido como Gustavo Cerati, fue un músico, cantautor, multinstrumentista y productor discográfico argentino que obtuvo reconocimiento internacional por haber sido el líder, vocalista, compositor y guitarrista de la banda de rock Soda Stereo. Parte de la prensa especializada y músicos lo consideran como uno de los artistas más influyentes del rock latinoamericano.',
    videourl:
      'https://youtube.com/playlist?list=PLq5-BKH1QxbyINEvbft63LkXPvIYmxBOm&si=cfR07LRRxsJ0mscw',
    price: 80,
    coverImg: 'cerati.gif',
    category: 'Rock',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.cerati.com/',
    youtube: 'https://www.youtube.com/channel/UCZuU_8S5VItTh-InZ0CV-rA',
    facebook: 'https://www.facebook.com/gustavocerati',
    instagram: 'https://www.instagram.com/cerati',
    twitter: 'https://x.com/cerati',
    spotify:
      'https://open.spotify.com/artist/1QOmebWGB6FdFtW7Bo3F0W?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/1374',
    apple: 'https://music.apple.com/mx/artist/gustavo-cerati/6059320',
    soundcloud: 'https://soundcloud.com/gustavocerati',
    tidal: 'https://listen.tidal.com/artist/3633298',
    wiki: 'https://es.wikipedia.org/wiki/Gustavo_Cerati',
    ticketmaster:
      'https://www.ticketmaster.com/gustavo-cerati-tickets/artist/1031570',
    tiktok: 'https://www.tiktok.com/@cerati',
    napster: 'https://music.amazon.com/artists/B000TDU27O/gustavo-cerati',
  },
  {
    title: 'Zoe',
    description:
      'Zoé es una banda mexicana de rock formada en 1997 en Cuernavaca, México. La banda es liderada por León Larregui y conformada por Sergio Acosta (guitarra), Jesús Báez (teclados), Ángel Mosqueda (bajo) y Rodrigo Guardiola (batería).',
    videourl: 'http://youtube.com/playlist?list=PL01F492352F201BCA',
    price: 50,
    coverImg: 'zoe.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://zoetheband.com/',
    youtube: 'https://www.youtube.com/channel/UCjstvmhLQiLmNDr7tiswbQg',
    facebook: 'https://www.facebook.com/Zoeoficial/',
    instagram: 'https://www.instagram.com/zoetheband/',
    twitter: 'https://twitter.com/zoetheband',
    spotify: 'https://open.spotify.com/artist/6IdtcAwaNVAggwd6sCKgTI',
    deezer: 'https://www.deezer.com/mx/artist/58585',
    apple: 'https://music.apple.com/mx/artist/zo%C3%A9/180563153',
    soundcloud: 'https://soundcloud.com/search?q=Zoe',
    tidal: 'https://listen.tidal.com/artist/3915689',
    wiki: 'https://es.wikipedia.org/wiki/Zoe',
    ticketImg: 'ticketmaster.jpg',
    ticketmaster: 'https://www.ticketmaster.com/zoe-tickets/artist/1919995',
    tiktok: 'https://www.tiktok.com/@zoeoficialmx?lang=es',
    napster: 'https://app.napster.com/artist/zoe',
  },
  {
    title: 'Duncan Dhu',
    description:
      'Duncan Dhu fue un grupo musical español de rock originario de San Sebastián (Guipúzcoa, España) formado el 4 de agosto de 1984 tras la fusión de los principales integrantes de los grupos Aristogatos y Los Dalton. Sus miembros originales fueron Mikel Erentxun (cantante hasta entonces del grupo Aristogatos), Diego Vasallo (vocalista y bajista del grupo Los Dalton) y Juan Ramón Viles (guitarra y batería de Los Dalton). Desde 1989 hasta 2016 estuvo compuesto por los dos primeros.El nombre del grupo hace referencia a un personaje de Secuestrado, una novela de 1886 de Robert Louis Stevenson. En ella, Duncan Dhu era el jefe de un clan escocés que fascinaba a Erentxun en aquellos años.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lcp1ADgi4y9JQPUNxB-DKPULIa822A1RI&si=WsL7sKi6CGSuikS_',
    price: 50,
    coverImg: 'duncandhu.jpg',
    category: 'Rock',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://vuelveduncandhu.com/',
    youtube: 'https://www.youtube.com/channel/UCj8sFiFmI7qCQzJWgUSwj-Q',
    facebook: 'https://www.facebook.com/duncandhuperu/',
    instagram: 'https://www.instagram.com/duncandhuoficial/',
    twitter: 'https://x.com/duncandhu',
    spotify: 'https://open.spotify.com/artist/2MLHBMApNE5h8wIufiTPs7',
    deezer: 'https://www.deezer.com/mx/artist/64661',
    apple: 'https://music.apple.com/mx/artist/duncan-dhu/18198835',
    soundcloud: 'https://soundcloud.com/duncan-dhu',
    tidal: 'https://listen.tidal.com/artist/13308',
    wiki: 'https://es.wikipedia.org/wiki/Duncan_Dhu',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Rock/Duncan-Dhu-Boletos',
    tiktok: 'https://www.tiktok.com/search/video?lang=es&q=Duncan%20Dhu&t=1726258574962',
    napster: 'https://music.amazon.com/artists/B0013CSVE6/duncan-dhu',
    
  },
  {
    title: 'Los Enanitos Verdes',
    description:
      'Enanitos Verdes es una banda argentina de rock en español, formada en 1979 en Mendoza, Argentina. A lo largo de su carrera han logrado colocarse en puestos importantes de las listas de popularidad argentinas, de América y el Caribe. Es considerado uno de los grupos más importantes del rock argentino de los años 1980 y del rock en español.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_l45A9XIPpJYvl6shEdIs8oUHRn81HRIJk',
    price: 50,
    coverImg: 'enanitosverdes.jpg',
    category: 'Rock',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.losenanitosverdes.net/',
    youtube: 'https://www.youtube.com/channel/UCEBcc_WHoyvM8_vofYot8aQ',
    facebook: 'https://www.facebook.com/EnanitosVerdes',
    instagram: 'https://www.instagram.com/enanitosverdes/',
    twitter: 'https://twitter.com/EnanitosVerdes_',
    spotify: 'https://open.spotify.com/artist/4TK1gDgb7QKoPFlzRrBRgR',
    deezer: 'https://www.deezer.com/mx/artist/133049',
    apple: 'https://music.apple.com/mx/artist/los-enanitos-verdes/132403',
    soundcloud: 'https://soundcloud.com/los-enanitos-verdes-official',
    tidal: 'https://listen.tidal.com/artist/56146',
    wiki: 'https://es.wikipedia.org/wiki/Enanitos_Verdes',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Rock/Enanitos-Verdes-Boletos',
    tiktok: 'https://www.tiktok.com/@enanitosverdes_oficial?lang=es',
    napster: 'https://music.amazon.com/artists/B000SZLJPW/los-enanitos-verdes',
    
  },
  {
    title: 'Los Bunkers',
    description:
      'Los Bunkers es una banda chilena de Rock alternativo oriunda de Concepción formada a finales de los noventa y activa de manera ininterrumpida hasta su separación en 2014. El grupo estuvo compuesto en un inicio por los hermanos Francisco y Mauricio Durán y Álvaro y Gonzalo López, junto a Manuel Lagos, quien sería más tarde reemplazado por Mauricio Basualto.Considerada como una de las bandas más importantes y exitosas de la música popular chilena,4​5​6​ destacó por su «chilenización» del rock clásico, fusionando sonidos provenientes de la raíz folclórica (esencialmente de la nueva canción chilena) con el estilo de grupos anglosajones como The Beatles y Cream, y de coterráneos penquistas como Los Tres y Santos Dumont.7​8​ Aunque con el paso del tiempo, la banda llegaría a experimentar con diversos estilos musicales que marcarían el sonido de sus últimos álbumes, tales como el britpop, la electrónica y la psicodelia.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kARMTOD4z67vCZEWPWBACeEzbDEBrr8Ug',
    price: 50,
    coverImg: 'losbunkers.jpg',
    category: 'Rock',
    location: 'Chile',
    locationImg: 'chileflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.losbunkers.cl/',
    youtube: 'https://www.youtube.com/channel/UCAp6F0mXIBHdxCGyO3nM7Tw',
    facebook: 'https://www.facebook.com/losbunkerschile',
    instagram: 'https://www.instagram.com/los_bunkers/',
    twitter: 'https://twitter.com/los_bunkers',
    spotify: 'https://open.spotify.com/artist/3RTAXX6KGdljBsOIupyZgT',
    deezer: 'https://www.deezer.com/mx/artist/16118',
    apple: 'https://music.apple.com/mx/artist/los-bunkers/41216290',
    soundcloud: 'https://soundcloud.com/los-bunkers',
    tidal: 'https://listen.tidal.com/artist/3656871',
    wiki: 'https://es.wikipedia.org/wiki/Los_Bunkers',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Rock/Los-Bunkers-Boletos',
    tiktok: 'https://www.tiktok.com/@losbunkers_oficial?lang=es',
    napster: 'https://music.amazon.com/artists/B000QKJO4M/los-bunkers',
    
  },
  {
    title: 'Siddhartha',
    description:
      'Siddhartha es un artista mexicano nominado al Latin Grammy en 2008 y 2014. Siddhartha, es un músico solista de indie rock. Participó como baterista del grupo de rock mexicano Zoé',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kJGa_qB3ZSr5tsyGhvX-DgP5NmJ4a4QiE',
    price: 80,
    coverImg: 'siddhartha.jpg',
    category: 'Electro-Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.siddhartha.mx/',
    youtube: 'https://www.youtube.com/c/YosoySiddhartha',
    facebook: 'https://www.facebook.com/siddharthamusica/',
    instagram: 'https://www.instagram.com/iamsiddhartha/',
    twitter: 'https://twitter.com/iamsiddhartha',
    spotify: 'https://open.spotify.com/artist/2aqFBHOpM9uIgBpUsdq09x',
    deezer: 'https://www.deezer.com/mx/artist/12780',
    apple: 'https://music.apple.com/mx/artist/siddhartha/124992824',
    soundcloud: 'https://soundcloud.com/search?q=Siddhartha',
    tidal: 'https://listen.tidal.com/artist/3795603',
    wiki: 'https://es.wikipedia.org/wiki/Siddhartha_(m%C3%BAsico)',
    ticketmaster:
      'https://www.ticketmaster.com/siddhartha-tickets/artist/1629841',
    tiktok: 'https://www.tiktok.com/@siddhartha_oficial?lang=es',
    napster: 'https://music.amazon.com/artists/B0013M20WA/siddhartha',
  },

  {
    title: 'Molotov',
    description:
      'Molotov es una banda mexicana de Rock Rap formada en 1995 y conformada por Micky Huidobro, Tito Fuentes, Randy Ebright y Paco Ayala. Su primer álbum, ¿Dónde jugarán las niñas?, registró más de un millón de copias vendidas. Fue señalado como uno de los mejores álbumes en 1997 por varios medios estadounidenses, país en el que compararon a la música del cuarteto con otras bandas ya establecidas como Beastie Boys, Rage Against the Machine y Red Hot Chili Peppers. La mayoría de las canciones del grupo son mejor conocidas por su contenido de sátira política y crítica social hacia el gobierno mexicano y la sociedad, motivo por el cual fueron objeto de censura al principio de su trayectoria.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kkSY575cmqQl2vPek-J8B-vr_DX-GPqlw',
    price: 50,
    coverImg: 'molotov.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://molotovoficial.mx/',
    youtube: 'https://www.youtube.com/channel/UCGeB59j5PTFjyIyWBNCo71g',
    facebook: 'https://www.facebook.com/molotovoficial/',
    instagram: 'https://www.instagram.com/molotovbanda/',
    twitter: 'https://twitter.com/MolotovBanda',
    spotify: 'https://open.spotify.com/artist/27Owkm4TGlMqb0BqaEt3PW',
    deezer: 'https://www.deezer.com/mx/artist/1410',
    apple: 'https://music.apple.com/mx/artist/molotov/101419',
    soundcloud: 'https://soundcloud.com/molotov-official',
    tidal: 'https://listen.tidal.com/artist/33008',
    wiki: 'https://es.wikipedia.org/wiki/M%C3%B3lotov',
    ticketmaster: 'https://www.ticketmaster.com/molotov-tickets/artist/795806',
    tiktok: 'https://www.tiktok.com/@molotovoficial?lang=es',
    napster: 'https://music.amazon.com/artists/B000WGZ01Q/molotov',
  },
  {
    title: 'Juanes',
    description:
      'Juan Esteban Aristizábal Vásquez, conocido por su nombre artístico Juanes, es un cantante, compositor y músico colombiano de pop latino y rock en español que combina diversos ritmos musicales. Ha sido premiado con 26 Grammy Latinos a lo largo de su trayectoria musical. Este es el mayor récord logrado por un cantante colombiano y el artista solista con más Grammy ganados. Además, ha vendido más de 16 millones de álbumes a nivel mundial.',
    videourl:
      'https://youtube.com/playlist?list=PLKkASunPqysMjZXnx34cPX4kWlQ7U_QOh',
    price: '$50',
    coverImg: 'Juanes.jpg',
    category: 'Balada',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.juanes.net/',
    youtube: 'https://www.youtube.com/channel/UC5sQe-dDsoobC6TRokL7SMQ',
    facebook: 'https://www.facebook.com/Juanes',
    instagram: 'https://www.instagram.com/juanes/',
    twitter: 'https://twitter.com/juanes',
    spotify: 'https://open.spotify.com/artist/0UWZUmn7sybxMCqrw9tGa7',
    deezer: 'https://www.deezer.com/mx/artist/70',
    apple: 'https://music.apple.com/mx/artist/juanes/103856',
    soundcloud: 'https://soundcloud.com/juanes-unplugged',
    tidal: 'https://listen.tidal.com/artist/17512',
    wiki: 'https://es.wikipedia.org/wiki/Juanes',
    ticketmaster: 'https://www.ticketmaster.com./juanes-tickets/artist/949573',
    tiktok: 'https://www.tiktok.com/@juanes?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJTAQA/juanes',
  },
  {
    title: 'Reik',
    description:
      'Reik es una banda musical originaria de Mexicali, Baja California, México y formada en 2003. Esta banda está principalmente conformada por Jesús Navarro (Voz principal y Piano), Julio Ramírez (Guitarra y Bajo) y Gilberto «Bibi» Marín (Guitarra y Batería). Los primeros 5 álbumes de la banda mexicana han sido de puras Baladas románticas, sin embargo, desde principios de 2015 sus últimas producciones abarcan el reguetón y el pop latino. Ha ganado un Latin Billboard Music Award, cuatro premios Los Premios MTV Latinoamérica y un Grammy latino..',
    videourl:
      'https://youtube.com/playlist?list=PLrjTzcOZnLIggDoZ3wTLkJc-NW8PxrLc6',
    price: '$50',
    coverImg: 'reik.jpg',
    category: 'Baladas',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.reik.tv/',
    youtube: 'https://www.youtube.com/channel/UC2rbcd_YR3j_klIv4AXXaZw',
    facebook: 'https://www.facebook.com/Reik',
    instagram: 'https://www.instagram.com/reikmx/',
    twitter: 'https://twitter.com/ReikMx',
    spotify: 'https://open.spotify.com/artist/0vR2qb8m9WHeZ5ByCbimq2',
    deezer: 'https://www.deezer.com/en/artist/4476',
    apple: 'https://music.apple.com/mx/artist/reik/117471769',
    soundcloud: 'https://soundcloud.com/reikmx',
    tidal: 'https://listen.tidal.com/artist/3633694',
    wiki: 'https://es.wikipedia.org/wiki/Reik',
    ticketmaster: 'https://www.ticketmaster.com.mx/reik-boletos/artist/1020467',
    tiktok: 'https://www.tiktok.com/@reikmx',
    napster: 'https://music.amazon.com/artists/B00136F8W0/reik',
  },
  {
    title: 'Jarabe De Palo',
    description:
      'Jarabe de Palo fue un grupo de rock de España formado en 1995. Estuvo liderado por el cantante, compositor y guitarrista Pau Donés hasta su fallecimiento el 9 de junio de 2020. Tuvo gran éxito a finales de los años 1990 y la década de los años 2000.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l-x7ZCzYkflGXPbFJPm4FdRgIRWY_JWGs&si=nPcLYh6N6kFzqIaT',
    price: '$50',
    coverImg: 'jarabe.gif',
    category: 'Baladas',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.jarabedepalo.com/',
    youtube: 'https://www.youtube.com/@JarabedepaloTV',
    facebook: 'https://www.facebook.com/JarabedepaloOFICIAL',
    instagram: 'https://www.instagram.com/jarabeoficial/',
    twitter: 'https://x.com/jarabeoficial',
    spotify: 'https://open.spotify.com/artist/5B6H1Dq77AV1LZWrbNsuH5',
    deezer: 'https://www.deezer.com/en/artist/5678',
    apple: 'https://music.apple.com/us/artist/jarabe-de-palo/534887935',
    soundcloud: 'https://soundcloud.com/jarabedepalo',
    tidal: 'https://listen.tidal.com/artist/15437',
    wiki: 'https://es.wikipedia.org/wiki/Jarabe_de_Palo',
    ticketmaster: 'https://www.ticketmaster.com/jarabe-de-palo-tickets/artist/1376995',
    tiktok: 'https://www.tiktok.com/@jarabe_de.pal0',
    napster: 'https://music.amazon.com/artists/B000TE037W/jarabe-de-palo',
  },
  {
    title: 'Los Babasonicos',
    description:
      'Adrián Rodríguez, conocido artísticamente como Adrián Dárgelos, es un cantante, compositor y escritor argentino, líder del grupo musical Babasónicos..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_ky66eKBgqN1lYG4_209prtDgIQD40jazY',
    price: '$50',
    coverImg: 'LosBabsonicos.jpg',
    category: 'Rock',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.babasonicos.com/',
    youtube: 'https://www.youtube.com/babasonicos',
    facebook: 'https://www.facebook.com/BabasonicosOficial',
    instagram: 'https://www.instagram.com/Babasonicos/',
    twitter: 'https://twitter.com/Babasonicos',
    spotify: 'https://open.spotify.com/artist/2F9pvj94b52wGKs0OqiNi2',
    deezer: 'https://www.deezer.com/mx/artist/4998',
    apple: 'https://music.apple.com/mx/artist/babas%C3%B3nicos/142116459',
    soundcloud: 'https://soundcloud.com/babasonicos',
    tidal: 'https://listen.tidal.com/artist/3843795',
    wiki: 'https://es.wikipedia.org/wiki/Babas%C3%B3nicos',
    ticketmaster:
      'https://www.ticketmaster.com./babasonicos-tickets/artist/837396',
    tiktok: 'https://www.tiktok.com/@babasonicos?lang=es',
    napster: 'https://music.amazon.com/artists/B004C6LGP6/babas%C3%B3nicos',
  },
  {
    title: 'Mikel Erentxun',
    description:
      'Mikel Erentxun Acosta (Caracas, 23 de febrero de 1965) es un cantante y escritor Español, nacido en Venezuela, donde residió desde su primer año de vida para posteriormente, con sus padres, mudarse a San Sebastián, donde reside actualmente.1​ Conocido por ser el líder de la banda española Duncan Dhu. Actualmente continúa destacándose como cantante solista.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_ll8p97KNMcVAgmqOhopNdCio_gW_Jo-0s&si=em6M3fDaE2ArIkfD',
    price: '$50',
    coverImg: 'mikelerenxtun.jpg',
    category: 'Rock',
    location: 'Venezuela',
    locationImg: 'venezuelaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.mikelerentxun.ws/',
    youtube: 'https://www.youtube.com/@mikelerentxun_oficial',
    facebook: 'https://www.facebook.com/mikelerentxun',
    instagram: 'https://www.instagram.com/erentxun/',
    twitter: 'https://x.com/MikelErentxun',
    spotify: 'https://open.spotify.com/artist/7thnnayFyJnVOAJrpe5wMC',
    deezer: 'https://www.deezer.com/mx/artist/13508',
    apple: 'https://music.apple.com/mx/artist/mikel-erentxun/725328',
    soundcloud: 'https://soundcloud.com/mikelerentxunlasmalasinfluencias',
    tidal: 'https://listen.tidal.com/artist/12190',
    wiki: 'https://es.wikipedia.org/wiki/Mikel_Erentxun',
    ticketmaster:
      'https://www.ticketmaster.com.mx/mikel-erentxun-tour-2024-guadalajara-19-11-2024/event/3D00610FE3913C13',
    tiktok: 'https://www.tiktok.com/@mikelerentxun?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0013CPN3S/mikel-erentxun',
  },
  {
    title: 'Nacha Pop',
    description:
      'Nacha Pop fue un grupo musical español del Pop rock de los años 80, formado en medio de la entonces recién nacida movida madrileña. En su formación inicial participaron Antonio Vega y Nacho García Vega (guitarras y voces), Carlos Brooking (bajo) y Ñete (batería). Formado en 1978, tras desintegrarse el grupo Uhu Helicopter. 10 años más tarde se disolvió, aunque en 2007 resurgieron hasta 2009, año de la muerte de Antonio Vega.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_naz_9H66T7urYeI9HmoKgv8YBqOnI8HrY&si=BQuQntk0NweL2uj-',
    price: '$50',
    coverImg: 'nachapop.jpg',
    category: 'Rock',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://nachapop.es/',
    youtube: 'https://www.youtube.com/user/NachaPopVEVO',
    facebook: 'https://www.facebook.com/NachaPopOficial',
    instagram: 'https://www.instagram.com/nacha__pop/',
    twitter: 'https://x.com/NachaPopOficial',
    spotify: 'https://open.spotify.com/artist/1CdLG4i1rTEOsex2UE0jCH?play=true&utm_source=open.spotify.com&utm_medium=open',
    deezer: 'https://www.deezer.com/mx/artist/4788',
    apple: 'https://music.apple.com/mx/artist/nacha-pop/1525701',
    soundcloud: 'https://soundcloud.com/nacha-pop',
    tidal: 'https://listen.tidal.com/artist/13180',
    wiki: 'https://es.wikipedia.org/wiki/Nacha_Pop',
    ticketmaster:
      'https://www.ticketmaster.com./nacha-pop-tickets/artist/2358818',
    tiktok: 'https://www.tiktok.com/search?q=nacha%20pop&t=1726026255960',
    napster: 'https://music.amazon.com/artists/B0017P3FD6/nacha-pop',
  },
  {
    title: 'Gianluca Grignani',
    description:
      'Gianluca Grignani (Milán, Lombardía, 7 de abril de 1972) es un cantautor, guitarrista, productor discográfico y músico italiano. Creció en la periferia norte de la metrópolis Lombarda, se traslada a Brianza a la edad de 17 años. Desde joven comienza a escribir canciones (influenciado por grupos como The Beatles y The Police, también por el cantautor italiano Lucio Battisti) y toca en su pequeño ámbito local.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_leZQSTI4LI2dIGVBATGLuif1aIS-pjvoc&si=clraczIF5G2z1yAb',
    price: '$50',
    coverImg: 'gianluca.jpg',
    category: 'Rock',
    location: 'Italia',
    locationImg: 'italy.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.gianlucagrignani.it/',
    youtube: 'https://www.youtube.com/channel/UColz1FbIlymB0lfzhESxtow',
    facebook: 'https://www.facebook.com/grignanigianluca/',
    instagram: 'https://www.instagram.com/gianlucagrignaniofficial/',
    twitter: 'https://x.com/grignanipage',
    spotify: 'https://open.spotify.com/artist/0H1InhXaXQPL1aj0mvHemU',
    deezer: 'https://www.deezer.com/mx/artist/8321',
    apple: 'https://music.apple.com/us/artist/gianluca-grignani/45542',
    soundcloud: 'https://soundcloud.com/addal-remix/gianluca-grignani-mi-historia-entre-tus-dedos-addal-remix',
    tidal: 'https://listen.tidal.com/artist/33120',
    wiki: 'https://es.wikipedia.org/wiki/Gianluca_Grignani',
    ticketmaster:
      'https://www.ticketmaster.com./gianluca-grignani-tickets/artist/773295',
    tiktok: 'https://www.tiktok.com/@gianlucagrignani',
    napster: 'https://music.amazon.com/artists/B000VZV6ES/gianluca-grignani',
  },
{
    title: 'La Ley',
    description:
      'La Ley fue una banda chilena de rock formada en 1987 por iniciativa del tecladista y guitarrista Andrés Bobe de Paraíso Perdido y su amigo, el tecladista Rodrigo Aboitiz de Aparato Raro, iniciando así un nuevo proyecto, donde más tarde se uniría Beto Cuevas, Mauricio Claveria, Luciano Rojas y Pedro Frugone..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_ns2WIZzYZ1xfEoOU6EsvSsddQfeIn4uYw',
    price: '$50',
    coverImg: 'LaLey.jpg',
    category: 'Rock',
    location: 'Chile',
    locationImg: 'chileflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/La_Ley_(banda)',
    youtube: 'https://www.youtube.com/channel/UC3fYrtq16pO1zeObN4DVEcA',
    facebook: 'https://www.facebook.com/LaLeyOficial',
    instagram: 'https://www.instagram.com/LaLeyOficial/',
    twitter: 'https://twitter.com/LaLeyOficial',
    spotify: 'https://open.spotify.com/artist/1ZVoRDO29AlDXiMkRLMZSK',
    deezer: 'https://www.deezer.com/mx/artist/6081',
    apple: 'https://music.apple.com/mx/artist/la-ley/2143112',
    soundcloud: 'https://soundcloud.com/la-ley-official',
    tidal: 'https://listen.tidal.com/artist/13179',
    wiki: 'https://es.wikipedia.org/wiki/La_Ley_(banda)',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=La+Ley',
    tiktok: 'https://www.tiktok.com/search?lang=es&q=La%20Ley&t=1654468265630',
    napster: 'https://music.amazon.com/artists/B000WGN7TI/la-ley',
  },
  {
    title: 'Caifanes',
    description:
      'Caifanes es una banda mexicana de rock en español que estuvo activa de 1987 a 1995,2​ y de 2011 hasta la actualidad.3​ La banda empezó como un cuarteto conformado por Saúl Hernández (guitarra y voz), Alfonso André (batería), Sabo Romo (bajo) y Diego Herrera (teclados, saxofón y percusiones), lanzando en 1988 el álbum debut Caifanes. En 1989 se unió Alejandro Marcovich como guitarra líder..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lfdPhaQpXNbHr2XyZ8FfTcR_qek-4Z86M',
    price: null,
    coverImg: 'Caifanes.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.caifanes.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCYTPYsuIRSu7o9xmeB_L4_g',
    facebook: 'https://www.facebook.com/CaifanesMex',
    instagram: 'https://www.instagram.com/caifanesmex/',
    twitter:
      'https://twitter.com/CaifanesMex?fbclid=IwAR2vNtuY5IcJfjBXbnWWkyXRY1nSgl0yqgt1YYuxVtLBYEgkiLKADHJiVng',
    spotify: 'https://open.spotify.com/artist/1GImnM7WYVp95431ypofy9',
    deezer: 'https://www.deezer.com/mx/artist/3214',
    apple: 'https://music.apple.com/mx/artist/caifanes/168944293',
    soundcloud: 'https://soundcloud.com/caifanesofficial',
    tidal: 'https://listen.tidal.com/artist/3642161',
    wiki: 'https://es.wikipedia.org/wiki/Caifanes_(banda)',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Caifanes',
    tiktok: 'https://www.tiktok.com/@caifanes.oficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00137ENB6/caifanes',
  },
  {
    title: 'Heroes del Silencio',
    description:
      'Héroes del Silencio (a veces abreviado Héroes o por sus iniciales HDS) fue un grupo español de rock radicado en Zaragoza, formado inicialmente por Juan Valdivia (guitarra solista) y Enrique Bunbury (voz y guitarra rítmica), y completado con Joaquín Cardiel (bajo) y Pedro Andreu (batería), a mediados de los años 80. Experimentaron un gran éxito en España e Hispanoamérica, así como en varios países europeos, incluyendo Alemania, Bélgica, Suiza, Francia e Italia, convirtiéndose en uno de los grupos más exitosos de la historia del rock en español.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kIMtMkZnhQ69IcFBSsZryY9kOh_cyAg3g',
    price: '$50',
    coverImg: 'heroesdelsilencio.jpeg',
    category: 'Rock',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.heroesdelsilencio.es/',
    youtube: 'https://www.youtube.com/channel/UCDOkA4mO0BgCGM0U1w7hNpg',
    facebook: 'https://www.facebook.com/HeroesdelSilencioOficial/',
    instagram: 'https://www.instagram.com/heroesdelsilenciook/',
    twitter: 'https://twitter.com/HdS__Oficial',
    spotify: 'https://open.spotify.com/artist/3qAPxVwIQRBuz5ImPUxpZT',
    deezer: 'https://www.deezer.com/mx/artist/6137',
    apple: 'https://music.apple.com/mx/artist/h%C3%A9roes-del-silencio/3097061',
    soundcloud: 'https://soundcloud.com/heroes-del-silencio',
    tidal: 'https://listen.tidal.com/artist/9002',
    wiki: 'https://es.wikipedia.org/wiki/H%C3%A9roes_del_Silencio',
    ticketmaster:
      'https://www.ticketmaster.com.mx/heroes-del-silencio-boletos/artist/778265',
    tiktok: 'https://www.tiktok.com/@heroesdelsilenciooficial?lang=es',
    napster: 'https://music.amazon.com/artists/B000SZG68C/h%C3%A9roes-del-silencio',
  },
  {
    title: 'Bunbury',
    description:
      'Enrique Ortiz de Landázuri Izarduy (Zaragoza, 11 de agosto de 1967),​ más conocido como Enrique Bunbury o simplemente Bunbury, es un cantante, compositor y músico español. Es reconocido por haber sido el líder de la banda Héroes del Silencio. Comenzó su actividad musical durante los primeros años de la década de los 80, formando parte de Apocalipsis, Rebel Waltz, Proceso Entrópico, Zumo de Vidrio y Niños del Brasil, aunque su consagración llegó siendo el líder de Héroes del Silencio, grupo musical de gran éxito y considerado por muchos como una de las mejores bandas del rock español.​ Después de la ruptura del grupo en 1996, comenzó su carrera como solista al año siguiente consolidándose como una importante figura en el ámbito musical español e hispanoamericano.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mVJVHI0NfbNOsQVHaq0CbFTVaSvDwUiCA&si=Hi5ztivyVH1i9noC',
    price: '$50',
    coverImg: 'bunbury.jpg',
    category: 'Rock',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.enriquebunbury.com/',
    youtube: 'https://www.youtube.com/@EnriqueBunburyOficial',
    facebook: 'https://www.facebook.com/bunburyoficial',
    instagram: 'https://www.instagram.com/bunburyoficial/?hl=en',
    twitter: 'https://x.com/bunburyoficial',
    spotify: 'https://open.spotify.com/artist/4uqzzJg3ww5eH7IgGV7DMT',
    deezer: 'https://www.deezer.com/mx/artist/242817',
    apple: 'https://music.apple.com/mx/artist/bunbury/2307216',
    soundcloud: 'https://soundcloud.com/officialbunbury',
    tidal: 'https://listen.tidal.com/artist/9077',
    wiki: 'https://es.wikipedia.org/wiki/Enrique_Bunbury',
    ticketmaster:
      'https://www.ticketmaster.com.mx/bunbury-boletos/artist/796533',
    tiktok: 'https://www.tiktok.com/@bunburyoficial?lang=es',
    napster: 'https://music.amazon.com/artists/B000SA3BE4/bunbury',
  },
  {
    title: 'Cafe Tacvba',
    description:
      'Café Tacvba es una banda de rock alternativo procedente de Ciudad Satélite, Naucalpan, Estado de México. El grupo se conformó en 1989. Además del éxito comercial y de crítica que han logrado, el grupo es reconocido por su proyecto cultural vanguardista el cual mezcla el rock y sus temas habituales con letras, historias y sonidos extraídos de la cultura popular mexicana, esto último gracias al uso en diversas canciones de instrumentos como tololoche y jarana. Entre los principales premios obtenidos a lo largo de su carrera destacan el Grammy y el Grammy Latino..',
    videourl:
      'https://youtube.com/playlist?list=PLm45w0e6-VdHYy03NU_BVv1sPO6zHMGbM',
    price: '$50',
    coverImg: 'CafeTacuba.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://cafetacuba.com.mx/',
    youtube: 'https://www.youtube.com/c/Caf%C3%A9Tacvba',
    facebook: 'https://www.facebook.com/cafetacvbaoficial',
    instagram: 'https://www.instagram.com/cafetacvba/',
    twitter: 'https://twitter.com/cafetacuba_ct',
    spotify: 'https://open.spotify.com/artist/09xj0S68Y1OU1vHMCZAIvz',
    deezer: 'https://www.deezer.com/mx/artist/74311',
    apple: 'https://music.apple.com/mx/artist/caf%C3%A9-tacvba/267781297',
    soundcloud: 'https://soundcloud.com/cafe-tacvba',
    tidal: 'https://listen.tidal.com/artist/12575',
    wiki: 'https://es.wikipedia.org/wiki/Caf%C3%A9_Tacvba',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Caf%C3%A9+Tacvba',
    tiktok: 'https://www.tiktok.com/@tiktacvba?lang=es',
    napster: 'https://music.amazon.com/artists/B000VZWALG/caf%C3%A9-tacvba',
  },
  {
    title: 'Santana',
    description:
      'Carlos Humberto Santana Barragan (Autlán de Navarro, Jalisco; 20 de julio de 1947) es un guitarrista mexicano nacionalizado estadounidense.En 1966 fundó la banda Santana, pionera en fusionar la música latina con el rock. Santana ha vendido más de 100 millones de álbumes en todo el mundo (contando las ventas de su banda y su carrera en solitario). Ha ganado diez premios Grammy y tres premios Grammy Latino.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nFhKKg7Ch_9lnfxTogq0VhkZZdvB7wBg4',
    price: 50,
    coverImg: 'santana.gif',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.santana.com/',
    youtube: 'https://www.youtube.com/channel/UCI7pNDDat9jR8TjgF48aBDA',
    facebook: 'https://www.facebook.com/carlossantana',
    instagram: 'https://twitter.com/santanacarlos',
    twitter: 'https://twitter.com/santanacarlos',
    spotify: 'https://open.spotify.com/artist/6GI52t8N5F02MxU0g5U69P',
    deezer: 'https://www.deezer.com/mx/artist/553',
    apple: 'https://music.apple.com/mx/artist/santana/217174',
    soundcloud: 'https://soundcloud.com/santana-official',
    tidal: 'https://listen.tidal.com/artist/74',
    wiki: 'https://es.wikipedia.org/wiki/Carlos_Santana',
    ticketmaster: 'https://www.ticketmaster.com/santana-tickets/artist/741473',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Santana%20music&t=1654465241940',
    napster: 'https://music.amazon.com/artists/B000QKDMIG/santana',
  },
  {
    title: 'La Maldita Vecindad',
    description:
      'Maldita Vecindad y los Hijos del Quinto Patio es un grupo musical mexicano, formado en la Ciudad de México en 1985 que fusiona ritmos como el rock, el ska, el reggae, el punk e influencias tradicionales de la música mexicana como el danzón y el bolero..',
    videourl: 'https://youtube.com/playlist?list=UUi8jQ9TjWP8rfaOziw7lnTQ',
    price: '$50',
    coverImg: 'LaMalditaVecindad.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://maldita-vecindad-tienda.myshopify.com/collections/all',
    youtube: 'https://www.youtube.com/channel/UCi8jQ9TjWP8rfaOziw7lnTQ',
    facebook: 'https://www.facebook.com/malditavecindad',
    instagram: 'https://www.instagram.com/malditavecindad/',
    twitter: 'https://twitter.com/malditavecindad',
    spotify: 'https://open.spotify.com/artist/6WvDtNFHOWHfiNy8NVHujT',
    deezer: 'https://www.deezer.com/mx/artist/407496',
    apple:
      'https://music.apple.com/mx/artist/maldita-vecindad-y-los-hijos-del-quinto-patio/302942',
    soundcloud: 'https://soundcloud.com/maldita-vecindad',
    tidal: 'https://listen.tidal.com/artist/3634077',
    wiki:
      'https://es.wikipedia.org/wiki/Maldita_Vecindad_y_los_Hijos_del_Quinto_Patio',
    ticketmaster:
      'https://www.ticketmaster.com.mx/maldita-vecindad-tickets/artist/755054',
    tiktok: 'https://www.tiktok.com/@malditavecindadoficial?lang=es',
    napster: 'https://music.amazon.com/artists/B01IYB0HHM/maldita-vecindad-y-los-hijos-del-5to-patio',
  },
  {
    title: 'Manu Chao',
    description:
      'José Manuel Arturo Tomás Chao Ortega, más conocido como Manu Chao, es un cantautor francés nacionalizado español, multiinstrumentista exlíder del grupo Mano Negra. Hijo del periodista y escritor español Ramón Chao y sobrino del escritor y teólogo Xosé Chao Rego, se crio en un ambiente cultural muy rico en su infancia y primera juventud. Además de escritor y periodista, su padre fue un excelente pianista, ganador del Premio de Virtuosismo de Piano y caballero de las Artes y las Letras de Francia. ',
    videourl:
      'https://youtube.com/playlist?list=PL-Avjv9Wf8573SHT0C7fZorsDAedSeMV2',
    price: '$50',
    coverImg: 'ManuChao.jpg',
    category: 'Reggae',
    location: 'France',
    locationImg: 'France.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.manuchao.net/',
    youtube: 'https://www.youtube.com/channel/UCzGpxRavGnm6yym4Pt7B2sg',
    facebook: 'https://www.facebook.com/manuchao',
    instagram: 'https://www.instagram.com/manuchaoofficial/',
    twitter: 'https://twitter.com/manuchao',
    spotify: 'https://open.spotify.com/artist/6wH6iStAh4KIaWfuhf0NYM',
    deezer: 'https://www.deezer.com/mx/artist/6',
    apple: 'https://music.apple.com/mx/artist/manu-chao/2398554',
    soundcloud: 'https://soundcloud.com/manuchao',
    tidal: 'https://listen.tidal.com/artist/32449',
    wiki: 'https://es.wikipedia.org/wiki/Manu_Chao',
    ticketmaster:
      'https://www.ticketmaster.com/manu-chao-tickets/artist/1116562',
    tiktok: 'https://www.tiktok.com/@manuchaoofficial?lang=es',
    napster: 'https://music.amazon.com/artists/B0013JVCAY/hombres-g',
  },
  {
    title: 'Los Hombres G',
    description:
      'Hombres G es una banda musical española de rock and roll y pop rock. Esta banda fue fundada en Madrid en 1982 por su vocalista y bajista David Summers y uno de sus guitarristas, Rafa Gutiérrez, junto con Javier Molina y Daniel Mezquita. A mediados de los años 80 se popularizó en España con su primer álbum, para después ser conocidos internacionalmente, principalmente en Latinoamérica.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k3x0RGTPrh9nUMneZxffCvc1Hqv6LE05w',
    price: '$50',
    coverImg: 'LosHombresG.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://hombresg.net/',
    youtube: 'https://www.youtube.com/channel/UCp7WaCdDPxfLkDDZAjsYVnw',
    facebook: 'https://www.facebook.com/hombresgoficial/',
    instagram: 'https://www.instagram.com/hombresgoficial/',
    twitter: 'https://twitter.com/HombresG',
    spotify: 'https://open.spotify.com/artist/60uh2KYYSCqAgJNxcU4DA0',
    deezer: 'https://www.deezer.com/mx/artist/8548',
    apple: 'https://music.apple.com/mx/artist/hombres-g/725400',
    soundcloud: 'https://soundcloud.com/hombres-g-official',
    tidal: 'https://listen.tidal.com/artist/12199',
    wiki: 'https://es.wikipedia.org/wiki/Hombres_G',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=+Hombres+G',
    tiktok: 'https://www.tiktok.com/@hombresgnet?lang=es',
    napster: 'https://music.amazon.com/artists/B0013JVCAY/hombres-g',
  },
  {
    title: 'El Tri',
    description:
      'El Tri es una banda de rock mexicano, fundada y liderada por Alejandro Lora, tras su salida de la banda Three Souls in My Mind, en 1981.El grupo ha vendido más de 30 millones de discos en su carrera y ha tenido cinco nominaciones para el Premio Grammy bajo la categoría de Mejor Álbum de Rock en Español por su álbumes: Cuando tú no estás (1998), Fin de siglo (1999), No podemos volar (2001), y 35 Años y lo que falta todavía (2005) y Mejor Álbum de Rock Vocal Dúo o Grupo para Alex Lora: Del Three a El Tri (40 rolas del alma. Mi mente y Mi aferración) (2009). En el 1971 El Three Souls in My Mind participó en el Festival de Rock y Ruedas de Avándaro.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nLv3OTdsRbcQzv8uRtge4pnzvZgi8EdN0',
    price: '$50',
    coverImg: 'elTri.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.eltri.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCTSruUknzSv383nJRPtGY-g',
    facebook: 'https://www.facebook.com/ElTRiOficial/',
    instagram: 'https://www.instagram.com/eltri_oficial/',
    twitter: 'https://twitter.com/RolasElTri',
    spotify: 'https://open.spotify.com/artist/3HgZDevp7GspkLUAa5cKne',
    deezer: 'https://www.deezer.com/mx/artist/10909',
    apple: 'https://music.apple.com/mx/artist/el-tri/2137426',
    soundcloud: 'https://soundcloud.com/el-tri-official',
    tidal: 'https://listen.tidal.com/artist/12481',
    wiki: 'https://es.wikipedia.org/wiki/El_Tri_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/el-tri-tickets/artist/758955',
    tiktok: 'https://www.tiktok.com/@eltrioficial?lang=es',
    napster: 'https://music.amazon.com/artists/B00130KKFG/el-tri',
  },
  {
    title: 'INSITE',
    description:
      'Insite es considerada la banda de rock más importante de Mexicali de los últimos años. Su sonido es intenso y estridente, y a menudo contrasta con letras y melodías vocales mucho más sutiles. Este poderoso estilo los ha llevado a tocar en escenarios tan icónicos como el Teatro Metropolitan, El Plaza Condesa y el House of Blues en L.A. La banda se formó en 2001, pero no fue hasta 2007 que debutaron oficialmente con su material discográfico “Una Vida No Es Suficiente”. Gracias a ese álbum fueron reconocidos ya no sólo en su natal Mexicali, sino en el resto de la escena de la música independiente en México y Latinoamérica. Actualmente Insite está conformado por César Cossío y Gabriel Felix en las guitarras, Carlos Esquer en el bajo, Johanan Lam en la batería y Antonio Altamirano en la voz, el más reciente integrante que llegó a Insite para renovar su energía y seguir consolidando la carrera de la banda.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlXcBYlfhaTjPB4I4FnNCkaH&si=pNl64GOGGhYYvUHl',
    price: '$50',
    coverImg: 'insite.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://negropasion.com/collections/insite?srsltid=AfmBOoqSpBhHpkYc8DQprkiscRgyJPTob4aqmCY8-RblMmp3_BSBtj5o',
    youtube: 'https://www.youtube.com/@mikelerentxun_oficial',
    facebook: 'https://www.facebook.com/insitemusic',
    instagram: 'https://www.instagram.com/insitemusic/',
    twitter: 'https://x.com/rockinsite?lang=en',
    spotify: 'https://open.spotify.com/artist/2Dh7EC5nA37i7JH3UNB8YX',
    deezer: 'https://www.deezer.com/mx/artist/391678',
    apple: 'https://music.apple.com/mx/artist/insite/1606153104',
    soundcloud: 'https://soundcloud.com/user-191702099',
    tidal: 'https://listen.tidal.com/artist/3570987',
    wiki: 'https://negropasion.com/collections/insite?srsltid=AfmBOoqSpBhHpkYc8DQprkiscRgyJPTob4aqmCY8-RblMmp3_BSBtj5o',
    ticketmaster:
      'https://www.ticketmaster.com.mx/insite-thermo-guadalajara-03-10-2024/event/3D00608FC15825F9',
    tiktok: 'https://www.tiktok.com/@insitemusic?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0013N74QG/insite',
  },
]
export default CardData7
