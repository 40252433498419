const CardData15 = [
  {
    title: 'Conjunto Primavera',
    description:
      'Conjunto Primavera es una agrupación de música regional mexicana especializada en el género norteño-sax y originaria de Ojinaga, Chihuahua. En las décadas de 1990 y 2000 se convirtió en el grupo más popular e influyente del género.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mUWGFd602AQ-HqCJ0uIj9cEVCs8kuVwrU&si=nQbNalcwsfTvn_zF',
    price: 80,
    coverImg: 'conjuntoprimavera.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://primaveraoficial.com/',
    youtube: 'https://www.youtube.com/channel/UCEQP8hyv1YTJq4QEXeC09zQ',
    facebook: 'https://www.facebook.com/conjuntoprimavera/?locale=es_LA',
    instagram: 'https://www.instagram.com/conjunto_primavera/?hl=en',
    twitter: 'https://x.com/ConjPrimOficial',
    spotify: 'https://open.spotify.com/artist/3nFB4eMP5gdqee2eQb8nZb?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/320165',
    apple: 'https://music.apple.com/mx/artist/conjunto-primavera/65429927',
    soundcloud: 'https://soundcloud.com/conjunto-primavera-1',
    tidal: 'https://listen.tidal.com/artist/3642156',
    wiki: 'https://es.wikipedia.org/wiki/Conjunto_Primavera',
    ticketmaster:'https://www.ticketmaster.com/conjunto-primavera-tickets/artist/830462',
    tiktok: 'https://www.tiktok.com/@conjunto_primavera?_t=8ha4tDrYE5t&_r=1',
    napster: 'https://music.amazon.com/artists/B000QKS65U/conjunto-primavera',
  },{
    title: 'Los Temerarios',
    description:
      'Los Temerarios son una agrupación mexicana del género grupero originaria de Fresnillo, Zacatecas, México. Fue formada en el año 1977 por los hermanos Adolfo Ángel y Gustavo Ángel Alba. Durante sus primeros años, fueron conocidos como Conjunto La Brisa.',
    videourl:
      'https://youtube.com/playlist?list=UULFGmAE7KNR1w6dkLWfe8u-Jw&si=A0ccAzBhAGVbEmTt',
    price: 80,
    coverImg: 'lostemerarios.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.lostemerarios.net/',
    youtube: 'https://www.youtube.com/channel/UCGmAE7KNR1w6dkLWfe8u-Jw',
    facebook: 'https://www.facebook.com/Official.Los.Temerarios',
    instagram: 'https://www.instagram.com/lostemerarios',
    twitter: 'https://x.com/los_temerarios',
    spotify: 'https://open.spotify.com/artist/3YbOSxo85kla7RID8ugnW3?si=d8TcrLCjScGhDcp3fXVcOg&nd',
    deezer: 'https://www.deezer.com/mx/artist/373939',
    apple: 'https://music.apple.com/mx/artist/los-temerarios/172855589',
    soundcloud: 'https://soundcloud.com/lostemerarios',
    tidal: 'https://listen.tidal.com/artist/3594815',
    wiki: 'https://es.wikipedia.org/wiki/Los_Temerarios',
    ticketmaster:'https://www.ticketmaster.com/los-temerarios-tickets/artist/755226',
    tiktok: 'https://www.tiktok.com/@lostemerarios_',
    napster: 'https://music.amazon.com/artists/B00130F27M/los-temerarios',
  },
  {
    title: 'Los Bukis',
    description:
      'Los Bukis es una banda mexicana de música grupera fundada en el año 1971 en la ciudad de Ario de Rosales, Michoacán, México, por el cantautor Marco Antonio Solís y su primo Joel Solís. Su primer éxito fue Falso amor y su estilo se hizo muy popular en Latinoamérica y los Estados Unidos de América. Vendieron en un año un millón de discos obteniendo así un "Disco de Diamante", además de ganar premios Billboard como mejor grupo latino entre los años 1982 y 1995.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m1X3TUJ1quzYALJ38ecdqy1ZVqJlfPDpQ&si=YhlrsF7HlgWc--A5',
    price: 80,
    coverImg: 'losbukis.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bukis.com/',
    youtube: 'https://www.youtube.com/channel/UClrHSSKR4lDucnfC7gnMMHg',
    facebook: 'https://www.facebook.com/somoslosbukis/',
    instagram: 'https://www.instagram.com/somoslosbukis/',
    twitter: 'https://x.com/somoslosbukis',
    spotify: 'https://open.spotify.com/artist/16kOCiqZ1auY4sokSeZuKf',
    deezer: 'https://www.deezer.com/mx/artist/13408',
    apple: 'https://music.apple.com/mx/artist/los-bukis/128669481',
    soundcloud: 'https://soundcloud.com/los-bukis-1',
    tidal: 'https://listen.tidal.com/artist/3909133',
    wiki: 'https://es.wikipedia.org/wiki/Los_Bukis',
    ticketmaster:
      'https://www.ticketmaster.com/los-bukis-tickets/artist/754825',

    tiktok: 'https://www.tiktok.com/@somoslosbukis',
    napster: 'https://music.amazon.com/artists/B00130DL8O/los-bukis',
  },
  {
    title: 'Marco Antonio Solis',
    description:
      'Marco Antonio Solís Sosa (Ario de Rosales, Michoacán, México; 29 de diciembre de 1959) es un músico, cantante, compositor, productor musical, actor y empresario mexicano. Junto a su primo Joel Solís, fue fundador de la agrupación musical grupera mexicana Los Bukis, del cual fue vocalista, músico, productor y autor de la mayoría de sus canciones; al inicio tocaba los teclados y posteriormente los timbales y la guitarra.[cita requerida] Es en donde se hizo conocido por su música y por ser uno de los integrantes de la agrupación.',
    videourl:
      'https://youtube.com/playlist?list=PLjJ34Xnquyyc6jWm19jqCBS7SP49S3yyK&si=AeIyItwRaCR-CSmB',
    price: 80,
    coverImg: 'marcoantonio.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.marcoantoniosolis.com/',
    youtube: 'https://www.youtube.com/channel/UCIKg4QVg91aCWxXWlUkKDLw',
    facebook: 'https://www.facebook.com/marcoantoniosolis',
    instagram: 'https://www.instagram.com/marcoantoniosolis_oficial/',
    twitter: 'https://x.com/MarcoASolis',
    spotify: 'https://open.spotify.com/artist/3tJnB0s6c3oXPq1SCCavnd',
    deezer: 'https://www.deezer.com/mx/artist/9032',
    apple: 'https://music.apple.com/mx/artist/marco-antonio-sol%C3%ADs/332900',
    soundcloud: 'https://soundcloud.com/marco-antonio-solis-official',
    tidal: 'https://listen.tidal.com/artist/3675096',
    wiki: 'https://es.wikipedia.org/wiki/Marco_Antonio_Sol%C3%ADs',
    ticketmaster:
      'https://www.ticketmaster.com/marco-antonio-solis-tickets/artist/781016',

    tiktok: 'https://www.tiktok.com/@marcoasolis_oficial',
    napster: 'https://music.amazon.com/artists/B000V65U8A/marco-antonio-sol%C3%ADs',
  },
  {
    title: 'Neto Bernal',
    description:
      'Ernesto Bernal Contreras, mejor conocido como Neto Bernal, es un cantante de música regional, procedente de Mexicali, Baja California, México. El joven, que nació el 1 de octubre de 1997, comparte junto con su madre el origen en la ciudad fronteriza, Mexicali, mientras que su padre viene de Sonora. El artista, de tan sólo 19 años, creció allí junto con su hermano menor, Alexis Bernal.',
    videourl:
      'http://youtube.com/playlist?list=PLaSnKw4FFePPPOXaDVDk3SObo2PbAeqbp',
    price: 50,
    coverImg: 'NetoBernal.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UC0tWKR_TBob21vJTEO67UfA',
    facebook: 'https://www.facebook.com/NetoBernalOficial',
    instagram: 'https://www.instagram.com/netobernaloficial/?hl=en',
    twitter: 'https://twitter.com/elnetillobernal',
    spotify:
      'https://open.spotify.com/artist/0X8PwlFMDK85fIadwq3cA5?si=wQEx4ri9Q_yhkyqLdv1t5w&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/10693157',
    apple: 'https://music.apple.com/mx/artist/neto-bernal/1133791209',
    soundcloud: 'https://soundcloud.com/neto-bernal',
    tidal: 'https://listen.tidal.com/artist/7990895',
    wiki:
      'https://laletrade.com/biografias/regional-mexicano/18635-neto-bernal',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@netobernal?lang=es',
    napster: 'https://music.amazon.com/artists/B01ICX89MW/neto-bernal',
  },{
    title: 'Gianluca Grignani',
    description:
      'Gianluca Grignani (Milán, Lombardía, 7 de abril de 1972) es un cantautor, guitarrista, productor discográfico y músico italiano. Creció en la periferia norte de la metrópolis Lombarda, se traslada a Brianza a la edad de 17 años. Desde joven comienza a escribir canciones (influenciado por grupos como The Beatles y The Police, también por el cantautor italiano Lucio Battisti) y toca en su pequeño ámbito local.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_leZQSTI4LI2dIGVBATGLuif1aIS-pjvoc&si=clraczIF5G2z1yAb',
    price: '$50',
    coverImg: 'gianluca.jpg',
    category: 'Rock Pop',
    location: 'Italia',
    locationImg: 'italy.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.gianlucagrignani.it/',
    youtube: 'https://www.youtube.com/channel/UColz1FbIlymB0lfzhESxtow',
    facebook: 'https://www.facebook.com/grignanigianluca/',
    instagram: 'https://www.instagram.com/gianlucagrignaniofficial/',
    twitter: 'https://x.com/grignanipage',
    spotify: 'https://open.spotify.com/artist/0H1InhXaXQPL1aj0mvHemU',
    deezer: 'https://www.deezer.com/mx/artist/8321',
    apple: 'https://music.apple.com/us/artist/gianluca-grignani/45542',
    soundcloud: 'https://soundcloud.com/addal-remix/gianluca-grignani-mi-historia-entre-tus-dedos-addal-remix',
    tidal: 'https://listen.tidal.com/artist/33120',
    wiki: 'https://es.wikipedia.org/wiki/Gianluca_Grignani',
    ticketmaster:
      'https://www.ticketmaster.com./gianluca-grignani-tickets/artist/773295',
    tiktok: 'https://www.tiktok.com/@gianlucagrignani',
    napster: 'https://music.amazon.com/artists/B000VZV6ES/gianluca-grignani',
  },
  {
    title: 'Juan Luis Guerra',
    description:
      'Juan Luis Guerra Seijas (Ciudad Trujillo, 7 de junio de 1957) es un cantautor, arreglista, músico, productor musical y empresario dominicano. Con más de 40 años de carrera, ha vendido más de 30 millones de discos1​2​3​ y ganado numerosos premios, incluidos 27 Grammy Latinos, dos Grammy estadounidenses y once Premios Latin Billboard;4​ fue honrado como Icono de BMI en la 13.ª edición de los Premios Latinos de BMI, nombrado Compositor Latino del Año de 1995 de BMI, las composiciones de Guerra han ganado 14 Premios Latinos de BMI.',
    videourl:
      'https://youtube.com/playlist?list=PL96B39739C578C512&si=O7NTHEqi412be9vY',
    price: '$50',
    coverImg: 'juanluisguerra.jpg',
    category: 'Bachatta',
    location: 'Republica Dominicana',
    locationImg: 'dominican.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://juanluisguerra.com/',
    youtube: 'https://www.youtube.com/channel/UColz1FbIlymB0lfzhESxtow',
    facebook: 'https://www.facebook.com/juanluisguerra440/',
    instagram: 'https://www.instagram.com/juanluisguerra/',
    twitter: 'https://x.com/JuanLuisGuerra/',
    spotify: 'https://open.spotify.com/artist/3nlpTZci9O5W8RsNoNH559',
    deezer: 'https://www.deezer.com/mx/artist/4527779',
    apple: 'https://music.apple.com/us/artist/juan-luis-guerra/6786499',
    soundcloud: 'https://soundcloud.com/juan-luis-guerra-official',
    tidal: 'https://listen.tidal.com/artist/10564',
    wiki: 'https://es.wikipedia.org/wiki/Juan_Luis_Guerra',
    ticketmaster:
      'https://www.ticketmaster.com/juan-luis-guerra-tickets/artist/769172',
    tiktok: 'https://www.tiktok.com/@juanluisguerra',
    napster: 'https://music.amazon.com/artists/B000QJHP5I/juan-luis-guerra-4-40',
  },
  {
    title: 'Elefante',
    description:
      'Elefante es una banda de rock alternativo y pop rock formada en la Ciudad de México a principios de la década de 1990. Han lanzado cinco álbumes, y su álbum homónimo fue nominado a un Premio Grammy Latino en 2005. El grupo alcanzó prominencia cuando abrieron para Joaquín Sabina en un espectáculo en el Auditorio Nacional de la Ciudad de México. Tras varias giras por las Américas, abriendo para Shakira y Maná, su vocalista principal, Reyli, dejó la banda en 2003 para seguir una carrera en solitario. Como reemplazo, reclutaron a Jorge Martínez Guevara, exvocalista del grupo Caos, quien participó en su álbum de 2005, Elefante. Jorge Martínez dejó la banda en 2007, y el compositor/guitarrista Rafael Rafa López y Javi Cantero asumieron las funciones vocales en los lanzamientos posteriores.',
    videourl: 'https://youtube.com/playlist?list=PLGmZEpYPNGnH5RzBwJYcyMG4ARVnOhx1T&si=9AwQ8B6p_adavhB1',
    price: '$50',
    coverImg: 'elefante.jpg',
    category: 'Balada',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.elefanteoficial.com/',
    youtube: 'https://www.youtube.com/c/elefanteOficial',
    facebook: 'https://www.facebook.com/elefanteOficial/?locale=es_LA',
    instagram: 'https://www.instagram.com/elefanteoficial/',
    twitter: 'https://x.com/elefanteoficial',
    spotify: 'https://open.spotify.com/artist/5oYHL2SijkMY52gKIhYJNb',
    deezer: 'https://www.deezer.com/mx/artist/5308',
    apple: 'https://music.apple.com/mx/artist/elefante/7046239',
    soundcloud: 'https://soundcloud.com/elefante-oficial',
    tidal: 'https://listen.tidal.com/artist/616',
    wiki: 'https://en.wikipedia.org/wiki/Elefante_(Mexican_band)',
    ticketmaster:
      'https://www.ticketmaster.com.mx/reyli-barba-boletos/artist/1197375',
    tiktok: 'https://www.tiktok.com/@elefanteoficial?fbclid=IwAR0RYW8igsq6yXbBVavSVZ5X3moz2bMXDXdwIlpOUe8hgKX941Ah7kIQFHo',
    napster: 'https://music.amazon.com/artists/B000S2BG8U/reyli-barba',
  },
  {
    title: 'Reyli',
    description:
      'Reyli Barba Arrocha es un cantautor y actor mexicano. Es conocido por haber sido el primer vocalista de la agrupación Elefante; siendo reemplazado por Jorge Guevara a partir de 2004. Desde su separación del grupo, trabaja como solista..',
    videourl: 'http://youtube.com/playlist?list=PL37D2450D17D43D48',
    price: '$50',
    coverImg: 'Reyli.jpg',
    category: 'Balada',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.reylibarbamusic.com/',
    youtube: 'https://www.youtube.com/channel/UCVk903Ollml-lwINrNo6K6w',
    facebook: 'https://www.facebook.com/ReyliBarbaOficial',
    instagram: 'https://www.instagram.com/reylibarbaoficial/',
    twitter: 'https://twitter.com/Reyli_Barba',
    spotify: 'https://open.spotify.com/artist/69BUYvpG9MbjCyIZfsFdhJ',
    deezer: 'https://www.deezer.com/mx/artist/194042',
    apple: 'https://music.apple.com/mx/artist/reyli-barba/20456368',
    soundcloud: 'https://soundcloud.com/reylibarba',
    tidal: 'https://listen.tidal.com/artist/3602829',
    wiki: 'https://es.wikipedia.org/wiki/Reyli_Barba',
    ticketmaster:
      'https://www.ticketmaster.com.mx/reyli-barba-boletos/artist/1197375',
    tiktok: 'https://www.tiktok.com/@reylibarbaoficial?lang=es',
    napster: 'https://music.amazon.com/artists/B000S2BG8U/reyli-barba',
  },
  {
    title: 'Horacio Palencia',
    description:
      'Horacio Palencia es uno de los compositores más importantes en la actualidad del regional mexicano, donde suenan más de mil canciones de su autoría.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nrVYMBOp_u8-iH8TnI2zvmsn2sH2H-Vg8',
    price: 50,
    coverImg: 'HoracioPalencia.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/HoracioPalencia',
    youtube: 'https://www.youtube.com/channel/UCL9JY0TLQXbXM825DLTqHjw',
    facebook: 'https://www.facebook.com/HoracioPalencia',
    instagram: 'https://www.instagram.com/horaciopalencia/',
    twitter: 'https://twitter.com/horaciopalencia',
    spotify: 'https://open.spotify.com/artist/7arK8QUX7X61NIkChn9no2',
    deezer: 'https://www.deezer.com/mx/artist/1685815',
    apple: 'https://music.apple.com/mx/artist/horacio-palencia/213004989',
    soundcloud: 'https://soundcloud.com/horacio-palencia',
    wiki: 'https://es.wikipedia.org/wiki/Todo_depende_de_ti',
    ticketmaster:
      'https://www.ticketmaster.com/horacio-palencia-tickets/artist/1968743',
    tiktok: 'https://www.tiktok.com/@horaciopalenciatiktok?lang=es',
    napster: 'https://music.amazon.com/artists/B000VXKY3O/horacio-palencia',
  },
  {
    title: 'Sin Bandera',
    description:
      'Sin Bandera es un dúo argentino-mexicano formado por el argentino Noel Schajris y el mexicano Leonel García en 2000. Se separaron por única vez en 2008, volviéndose a juntar en noviembre de 2015 para realizar una gira de conciertos.1​2​ Hasta la fecha han lanzando seis álbumes de estudio: Sin Bandera (2002), De viaje (2003), Mañana (2005), Pasado (2006), Una última vez (Deluxe Edition) (2016) y Frecuencia (2022).',
    videourl:
      'https://youtube.com/playlist?list=PLEIi2hdHFECyaqn3iyPIlx9kgdgt1BgW4',
    price: '$50',
    coverImg: 'sinBandera.jpg',
    category: 'Baladas',
    location: 'mEXICO',
    locationImg: 'mexicoarg.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://sinbandera.com.mx/',
    youtube: 'https://www.youtube.com/channel/UC74jt8Y_Yc5YRzJr6-Ipi9g',
    facebook: 'https://www.facebook.com/sinbanderaoficial',
    instagram: 'https://www.instagram.com/sinbandera/',
    twitter: 'https://twitter.com/sinbandera',
    spotify: 'https://open.spotify.com/artist/7xeM7V59cA1X8GKyKKQV87',
    deezer: 'https://www.deezer.com/mx/artist/1461',
    apple: 'https://music.apple.com/mx/artist/sin-bandera/14483319',
    soundcloud: 'https://soundcloud.com/sinbanderaofficial',
    tidal: 'https://listen.tidal.com/artist/35406',
    wiki: 'https://es.wikipedia.org/wiki/Sin_Bandera',
    ticketmaster:
      'https://www.ticketmaster.com/sin-bandera-tickets/artist/849520',
    tiktok: 'https://www.tiktok.com/@sinbanderaoficial',
    napster: 'https://music.amazon.com/artists/B0013692GI/sin-bandera',
  },{
    title: 'Chayanne',
    description:
      'Elmer Figueroa Arce (San Lorenzo, Puerto Rico, 28 de junio de 1968), más conocido por su nombre artístico Chayanne, es un cantante, compositor, bailarín y actor puertorriqueño. Como solista, ha lanzado 22 álbumes de estudio y se estima que ha vendido alrededor de 40 millones de discos a nivel mundial, lo que lo convierte en uno de los artistas latinos con mayores ventas. Entre sus sencillos más exitosos se encuentran: «Fiesta en América», «Tu pirata soy yo», «Tiempo de vals», «Completamente enamorados», «Volver a nacer», «Atado a tu amor», «Dejaría todo», «Salomé», «Y tú te vas», «Torero», «Un siglo sin ti», «Si nos quedara poco tiempo», «Me enamoré de ti», «Humanos a Marte» y «Madre Tierra (Oye)».',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nZ8wumHpIqnQ3AqxxLgkkeH6wM1smtI_E&si=y_dlHYCUKKEh8UWt',
    price: '$50',
    coverImg: 'chayanne.jpg',
    category: 'Baladas',
    location: 'Mexico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://chayanne.com/',
    youtube: 'https://www.youtube.com/channel/UCltTIW3M5KliU-pu5nPaGUg',
    facebook: 'https://www.facebook.com/CHAYANNE/',
    instagram: 'https://www.instagram.com/chayanne/',
    twitter: 'https://x.com/CHAYANNEMUSIC',
    spotify: 'https://open.spotify.com/artist/1JbemQ1fPt2YmSLjAFhPBv',
    deezer: 'https://www.deezer.com/mx/artist/3873',
    apple: 'https://music.apple.com/us/artist/chayanne/1313256',
    soundcloud: 'https://soundcloud.com/chayanne-official',
    tidal: 'https://listen.tidal.com/artist/529',
    wiki: 'https://es.wikipedia.org/wiki/Chayanne',
    ticketmaster:
      'https://www.ticketmaster.com/chayanne-tickets/artist/754853',
    tiktok: 'https://www.tiktok.com/@chayanne',
    napster: 'https://music.amazon.com/artists/B000VK8E0W/chayanne',
  },
  {
    title: 'Alejandro Sanz',
    description:
      'Alejandro Sánchez Pizarro (Madrid, 18 de diciembre de 1968), conocido artísticamente como Alejandro Sanz, es un cantautor y compositor español. Ha vendido más de 25 millones de discos en todo el mundo y ha ganado 24 Grammy Latinos y 4 Grammy estadounidenses. Además, ha colaborado con diversos artistas tanto nacionales como internacionales. Es Hijo Adoptivo de la ciudad de Cádiz, debido a que pasó su infancia en Algeciras, ciudad de la cual fue nombrado embajador. Actuó en la ceremonia inaugural de los Juegos Olímpicos de Tokio 2020, interpretando junto con otros tres artistas de fama internacional la canción «Imagine» de John Lennon. Cada uno de estos artistas representaba a un continente, y Alejandro fue el representante de Europa.',
    videourl:
      'https://youtube.com/playlist?list=UUCEZY5vEQBsQN5i3ceiLo7g&si=Smw3v5Zfz-6qqm6x',
    price: '$50',
    coverImg: 'alejandrosanz.jpg',
    category: 'Baladas',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.alejandrosanz.com/',
    youtube: 'https://www.youtube.com/channel/UCV4S6_JoeDq9j6mgbpkmA0Q',
    facebook: 'https://www.facebook.com/ASanzOficial',
    instagram: 'https://www.instagram.com/alejandrosanz/',
    twitter: 'https://x.com/AlejandroSanz',
    spotify: 'https://open.spotify.com/artist/5sUrlPAHlS9NEirDB8SEbF',
    deezer: 'https://www.deezer.com/es/artist/4479',
    apple: 'https://music.apple.com/mx/artist/alejandro-sanz/162036',
    soundcloud: 'https://soundcloud.com/alejandro-sanz-official',
    tidal: 'https://listen.tidal.com/artist/12316',
    wiki: 'https://es.wikipedia.org/wiki/Alejandro_Sanz',
    ticketmaster:
      'https://www.ticketmaster.com/alejandro-sanz-tickets/artist/755193',
    tiktok: 'https://www.tiktok.com/@alejandrosanz.unico',
    napster: 'https://music.amazon.com/artists/B000QJHSMI/alejandro-sanz',
  },
  {
    title: 'Enrique Iglesias',
    description:
      'Enrique Miguel Iglesias Preysler (pronunciación en español: [enˈrike miˈɣel iˈɣlesjas ˈpɾejsleɾ]; nacido el 8 de mayo de 1975) es un cantante y compositor español. Comenzó su carrera discográfica a mediados de la década de 1990 en el sello mexicano Fonovisa y se convirtió en el artista de habla hispana más vendido de la década. Al llegar el nuevo milenio, hizo una exitosa transición al mercado mainstream de habla inglesa. Firmó un contrato por varios álbumes con Universal Music Group por 68 millones de dólares, con Universal Music Latino para lanzar sus álbumes en español y con Interscope Records para lanzar los álbumes en inglés. Enrique es el tercer y más joven hijo del cantautor español Julio Iglesias.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nHI0sn2K8okDPYDL1GvZs106OGhRzbOSY&si=VaYSYsHx5tc0i8RU',
    price: '$50',
    coverImg: 'enriqueiglesias.jpeg',
    category: 'Baladas',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.enriqueiglesias.com/',
    youtube: 'https://www.youtube.com/user/EnriqueIglesiasPlay',
    facebook: 'https://www.facebook.com/Enrique',
    instagram: 'https://www.instagram.com/enriqueiglesias/',
    twitter: 'https://x.com/enriqueiglesias',
    spotify: 'https://open.spotify.com/artist/7qG3b048QCHVRO5Pv1T5lw?si=8qklDBAsQcC9Q_jlnOvG7A&nd=1&dlsi=0a9aa814ac15476f',
    deezer: 'https://www.deezer.com/mx/artist/2792',
    apple: 'https://music.apple.com/mx/artist/enrique-iglesias/90895',
    soundcloud: 'https://soundcloud.com/enriqueiglesias',
    tidal: 'https://listen.tidal.com/artist/16994',
    wiki: 'https://es.wikipedia.org/wiki/Enrique_Iglesias',
    ticketmaster:
      'https://www.ticketmaster.com/enrique-iglesias-tickets/artist/777945',
    tiktok: 'https://www.tiktok.com/@enriqueiglesias',
    napster: 'https://music.amazon.com/artists/B000QKO654/enrique-iglesias',
  },
  {
    title: 'Ricky Martin',
    description:
      'Enrique "Ricky" Martín Morales (nacido el 24 de diciembre de 1971) es un cantante, compositor y actor puertorriqueño. Es conocido por su versatilidad musical, con una discografía que incorpora una amplia variedad de elementos, como el pop latino, la música dance, el reguetón, la salsa y otros géneros. Nacido en San Juan, Martín comenzó a aparecer en comerciales de televisión a la edad de nueve años y comenzó su carrera musical a los doce, como miembro de la boy band puertorriqueña Menudo. Inició su carrera en solitario en 1991 mientras estaba en Sony Music México, ganando reconocimiento en América Latina con el lanzamiento de sus dos primeros álbumes de estudio, Ricky Martin (1991) y Me Amarás (1993), ambos enfocados en baladas.',
    videourl:
      'https://youtube.com/playlist?list=PLfoZGrS-VficbBAtPOUOiLLdiUr7jYlbF&si=fE-2ZdJ79GA9sIoC',
    price: 80,
    coverImg: 'rickymartin.gif',
    category: 'Romantica',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rickymartinmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCGkkG-VYVXenlhVIqjH-9oA',
    facebook: 'https://www.facebook.com/RickyMartinOfficialPage',
    instagram: 'https://www.instagram.com/ricky_martin/',
    twitter: 'https://x.com/ricky_martin',
    spotify: 'https://open.spotify.com/artist/7slfeZO9LsJbWgpkIoXBUJ',
    deezer: 'https://www.deezer.com/mx/artist/2789',
    apple: 'https://music.apple.com/mx/artist/ricky-martin/217142',
    soundcloud: 'https://soundcloud.com/ricky-martin-official',
    tidal: 'https://listen.tidal.com/artist/535',
    wiki: 'https://es.wikipedia.org/wiki/Ricky_Martin',
    ticketmaster:
      'https://www.ticketmaster.com/ricky-martin-tickets/artist/755069',
    tiktok: 'https://www.tiktok.com/@ricky__martin',
    napster: 'https://music.amazon.com/artists/B000QJOMKE/ricky-martin',
  },
  {
    title: 'Luis Miguel',
    description:
      'Luis Miguel Gallego Basteri (San Juan, 19 de abril de 1970),conocido como Luis Miguel y apodado en ocasiones como «El Sol de México», es un cantante y productor musical mexicano.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUMRA7E0-zntqkMrhNKwYri',
    price: 80,
    coverImg: 'luismiguel.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://luismigueloficial.com/',
    youtube: 'https://www.youtube.com/channel/UCQHnOnsryRQmmr6pU3lAupg',
    facebook: 'https://www.facebook.com/luismiguelofficial',
    instagram: 'https://www.instagram.com/luismiguel/',
    twitter: 'https://twitter.com/LMXLM',
    spotify: 'https://open.spotify.com/artist/2nszmSgqreHSdJA3zWPyrW',
    deezer: 'https://www.deezer.com/mx/artist/6123',
    apple: 'https://music.apple.com/mx/artist/luis-miguel/336904',
    soundcloud: 'https://soundcloud.com/luismiguel_oficial',
    tidal: 'https://listen.tidal.com/artist/11993',
    wiki: 'https://en.wikipedia.org/wiki/Luis_Miguel',
    ticketmaster:
      'https://www.ticketmaster.com/luis-miguel-tickets/artist/762874',
    tiktok: 'https://www.tiktok.com/@lmxlm',
    napster: 'https://music.amazon.com/artists/B000SA3BTE/luis-miguel',
  },
  {
    title: 'Sin Bandera',
    description:
      'Sin Bandera es un dúo mexicoargentino formado por el mexicano Leonel García y el argentino Noel Schajris en 2000. Se separaron por única vez en 2008, volviéndose a juntar en noviembre de 2015 para realizar una gira de conciertos.1​2​ Hasta la fecha han lanzado seis álbumes de estudio: Sin Bandera (2001), De viaje (2003), Mañana (2005), Pasado (2006), Una última vez (Deluxe Edition) (2016) y Frecuencia (2022).',
    videourl:
      'https://youtube.com/playlist?list=PLEIi2hdHFECyaqn3iyPIlx9kgdgt1BgW4&si=QUD_GMs9JK_bHC7v',
    price: 80,
    coverImg: 'sinbandera.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexicoarg.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://sinbandera.com.mx/en/',
    youtube: 'https://www.youtube.com/user/Sinbandera',
    facebook: 'https://www.facebook.com/sinbanderaoficial/',
    instagram: 'https://www.instagram.com/sinbandera/',
    twitter: 'https://x.com/sinbandera',
    spotify: 'https://open.spotify.com/artist/7xeM7V59cA1X8GKyKKQV87',
    deezer: 'https://www.deezer.com/mx/artist/1461',
    apple: 'https://music.apple.com/mx/artist/sin-bandera/14483319',
    soundcloud: 'https://soundcloud.com/search?q=Sin%20Bandera',
    tidal: 'https://listen.tidal.com/artist/35406',
    wiki: 'https://es.wikipedia.org/wiki/Sin_Bandera',
    ticketmaster:
      'https://www.ticketmaster.com/sin-bandera-tickets/artist/849520',
    tiktok: 'https://www.tiktok.com/@sinbanderaoficial',
    napster: 'https://music.amazon.com/artists/B0013692GI/sin-bandera',
  },
]

export default CardData15
