const CardData39= [
  
  {
  title: 'AI For The Culture',
  description:
    'AI for the Culture es una comunidad dedicada a crear conciencia sobre diversas tecnologías de inteligencia artificial mientras celebramos el arte que nos representa. Este es un espacio seguro para la creatividad, la colaboración y el aprendizaje, donde los miembros pueden explorar la intersección entre la tecnología y la cultura. Nuestra misión es empoderar a las personas con conocimiento, fomentar conexiones significativas y proporcionar herramientas para navegar y monetizar el mundo digital con éxito.',
  videourl:
    'https://youtube.com/playlist?list=PLckwkAXMRfSLjieHbCiTG7oNB-ydvVkYF&si=k04aYWANZuHs8ZqQ',
  price: 80,
  coverImg: 'aifortheculture.jpeg',
  category: 'AI',
  location: 'USA',
  locationImg: 'usaflag.jpg',
  openSpots: null,
  likes: 1000,
  websiteurl: 'https://aifortheculturemusic.com/',
  youtube: 'https://www.youtube.com/channel/UCY2qt3dw2TQJxvBrDiYGHdQ',
  facebook: 'https://www.facebook.com/groups/aifortheculture/',
  instagram: 'https://www.instagram.com/_aifortheculture/',
  twitter: 'https://x.com/ai4theculture?lang=en',
  spotify: 'https://open.spotify.com/artist/4y6CMS7tqBf8ubmUuP9SQI',
  tiktok: 'https://www.tiktok.com/@_ai4theculture',
},
{
  title: 'Retro Raunch Radio',
  videourl:
    'https://youtube.com/playlist?list=PLFVpiQ6YIZzZd5nr2wfGaJN7G5Adq-tyr&si=IboVESW89WGeylZg',
  price: 80,
  coverImg: 'retroraunch.jpg',
  category: 'AI Chill',
  location: 'USA',
  locationImg: 'usaflag.jpg',
  openSpots: null,
  likes: 1000,
  websiteurl: 'https://retroraunchradio.bandcamp.com/',
  youtube: 'https://www.youtube.com/@RetroRaunchRadio',
  apple:"https://music.apple.com/ro/artist/retro-raunch-radio/1757038394",
  facebook: 'https://www.facebook.com/retroraunchreturn',
  instagram: 'https://www.instagram.com/_aifortheculture/',
  twitter: 'https://x.com/ai4theculture?lang=en',
  spotify: 'https://open.spotify.com/artist/4y6CMS7tqBf8ubmUuP9SQI',
  tiktok: 'https://www.tiktok.com/@_ai4theculture',
},
{
  title: 'Psy Dubby',
  description:
    "Psy Dubby es un artista y productor musical que fusiona los sonidos hipnóticos del psytrance con las vibraciones profundas y envolventes del dub. Su música crea paisajes sonoros inmersivos, combinando ritmos psicodélicos, bajos potentes y texturas atmosféricas que transportan a la audiencia a un viaje auditivo único. Inspirado por la cultura underground y las raíces del dub, Psy Dubby experimenta con efectos espaciales, delays y sintetizadores etéreos, logrando un equilibrio perfecto entre la energía del trance y la profundidad del dub. Con cada set y producción, busca conectar con el público a un nivel más profundo, creando experiencias sonoras que expanden la mente y el espíritu.",
  videourl:
    'https://youtube.com/playlist?list=PLYEF4_EDMF9783og8en8f2zy94X62yziD&si=27o-BX5JThVm8Ni2',
  price: 80,
  coverImg: 'psydubby.jpg',
  category: 'Chill',
  location: 'USA',
  locationImg: 'usaflag.jpg',
  openSpots: null,
  likes: 1000,
  websiteurl: 'https://ektoplazm.com/style/psy-dub',
  youtube: 'https://www.youtube.com/@psy_dubby',
  twitter:"https://x.com/davidchiarelli/status/1882095264456171787",
  facebook: 'https://www.facebook.com/photo.php?fbid=1029527774204347&id=264675674022898&set=a.268143667009432',
  spotify: 'https://open.spotify.com/playlist/5LYkaLqDJXIDP2giSxqTjv',
  deezer:"https://www.deezer.com/mx/album/610139312",
},
{
  title: 'EPIK THE DAWN',
  
  videourl:
    'https://youtube.com/playlist?list=PLYs4ULyHOyOCe5KuwnzJWEmxCXNj-1Fbw&si=HwVoUr8A4avNe6cn',
  price: 80,
  coverImg: 'epikthedawn.jpg',
  category: 'Electronica',
  location: 'USA',
  locationImg: 'usaflag.jpg',
  openSpots: null,
  likes: 1000,
  websiteurl: 'https://epikbeats.net/eb_beats/',
  youtube: 'https://www.youtube.com/channel/UChSnCDJWRtWXmndjVBXJ7Kg',
  apple:"https://music.apple.com/us/artist/epik-the-dawn/1523197247",
  twitter:"https://x.com/epikthedawn",
  facebook: 'https://www.facebook.com/EPIKTHEDAW',
  spotify: 'https://open.spotify.com/artist/7nEzAy3qFGr2ExdopbaCBX?si=UEOqsQ6uQPWP1vIL_1nEAQ&nd=1&dlsi=db1324a2e49a475f',
},
{
        title: 'The Dor Brothers',
        description:
          'Los Dor Brothers son pioneros en la producción de contenido visual innovador, destacándose como referentes en la comunidad de imágenes generadas por inteligencia artificial. Su carrera comenzó en el mundo de la música y la producción de videos tradicionales, explorando posteriormente la animación con software 3D. Sin embargo, fue en marzo de 2022 cuando revolucionaron su trayectoria al adoptar herramientas de generación de imágenes con IA, convirtiéndose en algunos de los primeros usuarios de plataformas como Stable Diffusion y Midjourney.Hoy en día, su compañía es reconocida globalmente por haber producido más de 200 clips que acumulan decenas de millones de vistas en todo el mundo. Han trabajado con nombres de renombre internacional como Snoop Dogg, The Game, Hugo Boss, French Montana, SiM e INSOMNIUM, consolidándose como líderes en la creación de contenido visual impactante.Además, los Dor Brothers han sido oradores en prestigiosas conferencias para empresas como Microsoft, demostrando su expertise y visión en el campo de la inteligencia artificial aplicada al arte. Como cofundadores de Event Station, han llevado la personalización artística a otro nivel mediante un fotomatón físico que ofrece obras únicas.Su talento trasciende el ámbito digital, ya que también producen comerciales de alta calidad para importantes marcas internacionales, los cuales se han exhibido en lugares icónicos como las calles de Milán y durante eventos deportivos de gran envergadura. Innovación, creatividad y excelencia definen a los Dor Brothers como líderes indiscutibles en la intersección del arte y la tecnología..',
        videourl:
          'https://youtube.com/playlist?list=PLF-cRDh2mrlX_jfkXxhg39BJM2PpeSgCw&si=vdXLAwUvAosgQxJi',
        price: 36,
        coverImg: 'dorbrothers.jpg',
        category: 'AI',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.thedorbrothers.com/',
        youtube: 'https://www.youtube.com/@thedorbrothers',
        facebook: 'https://www.facebook.com/Thedorbrothers',
        instagram: 'https://www.instagram.com/thedorbrothers/',
        twitter: 'https://x.com/thedorbrothers',
        tiktok: 'https://www.tiktok.com/@.thedorbrothers',
      },
      {
        title: 'Pink Floyd',
        description:
          'Pink Floyd fue una banda de rock británica, fundada en Londres en 1965. Considerada un ícono cultural del siglo XX y una de las bandas más influyentes, exitosas y aclamadas en la historia de la música popular, obtuvo gran popularidad dentro del circuito underground gracias a su música psicodélica y espacial, que con el paso del tiempo evolucionó hacia el rock progresivo y el rock sinfónico adquiriendo la popularidad con la que hoy son recordados. Es conocida por sus canciones de alto contenido filosófico, a veces de crítica política, junto a la experimentación sonora, las innovadoras portadas de sus discos y sus elaborados espectáculos en vivo. Sus ventas sobrepasan los 280 millones de álbumes vendidos en todo el mundo, 97.5 millones de ellos solamente en los Estados Unidos,convirtiéndose en una de las bandas con más ventas en la historia.',
        videourl:
          'https://youtube.com/playlist?list=PLMXnSDwMoUMtesFODEN4G6ZkSkDajN2Pt&si=nj726oeIHs22L_pv',
        price: 80,
        coverImg: 'pinkfloyd.gif',
        category: 'Rock',
        location: 'UK',
        locationImg: 'england.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.pinkfloyd.com/',
        youtube: 'https://www.youtube.com/channel/UCY2qt3dw2TQJxvBrDiYGHdQ',
        facebook: 'https://www.facebook.com/pinkfloyd',
        instagram: 'https://www.instagram.com/pinkfloyd/',
        twitter: 'https://twitter.com/pinkfloyd',
        spotify: 'https://open.spotify.com/artist/0k17h0D3J5VfsdmQ1iZtE9',
        deezer: 'https://www.deezer.com/mx/artist/860',
        apple: 'https://music.apple.com/mx/artist/pink-floyd/487143',
        soundcloud: 'https://soundcloud.com/officialpinkfloyd',
        tidal: 'https://listen.tidal.com/artist/9706',
        wiki: 'https://es.wikipedia.org/wiki/Pink_Floyd',
        ticketmaster:
          'https://www.ticketmaster.com/pink-floyd-tickets/artist/768805',
        tiktok: 'https://www.tiktok.com/@pink_floyd90?lang=es',
        napster: 'https://music.amazon.com/artists/B000QJPQK4/pink-floyd',
      },
      {
        title: 'The BonsAI Effect',
        description:
          'The BonsAi Effect está dedicado a explorar el fascinante mundo del arte generado por inteligencia artificial. Con una perfecta combinación de tecnología y creatividad, este canal muestra el asombroso potencial de la IA para crear obras de arte impresionantes y provocadoras. Adéntrate en un universo donde los algoritmos y la expresión artística se fusionan, y sé testigo de las impresionantes capacidades del arte generado por IA..',
        videourl:
          'https://youtube.com/playlist?list=PLMXnSDwMoUMtesFODEN4G6ZkSkDajN2Pt&si=nj726oeIHs22L_pv',
        price: 80,
        coverImg: 'bonsAI.png',
        category: 'AI',
        location: 'China',
        locationImg: 'england.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.youtube.com/@bonsai-effect',
        youtube: 'https://www.youtube.com/@bonsai-effect',
        facebook: 'https://www.facebook.com/profile.php?id=61560388537215',
        instagram: 'https://www.instagram.com/pinkfloyd/',
        twitter: 'https://x.com/ekuZhong',      
      },
      {
        title: 'Nacho AI',
        description:
          'Los videos musicales creados con inteligencia artificial (IA) están transformando la industria de la música y el arte visual de formas nunca antes vistas. Gracias a herramientas avanzadas como generadores de imágenes y videos basados en aprendizaje profundo, los artistas ahora pueden crear mundos visuales surrealistas, animaciones hiperrealistas y efectos especiales complejos que antes requerían equipos y presupuestos multimillonarios. La IA permite personalizar videos en tiempo real, generando contenido único adaptado a cada espectador, y también facilita la creación de escenarios virtuales, personajes y narrativas que evolucionan de manera dinámica según el tono y ritmo de la música. Este avance no solo está democratizando el acceso a la producción audiovisual, sino que también está fomentando una nueva ola de creatividad al permitir que los músicos y directores experimenten con estilos visuales que rompen las barreras del arte tradicional. En el futuro, la integración de IA con tecnologías como realidad virtual (VR) y realidad aumentada (AR) llevará los videos musicales a un nivel completamente nuevo, permitiendo experiencias inmersivas donde los espectadores puedan interactuar con los elementos visuales o incluso participar en la narrativa del video. Además, la IA será clave en la predicción de tendencias y en la creación de contenido optimizado para las plataformas digitales, lo que ayudará a los artistas a conectar más profundamente con sus audiencias. Sin embargo, este avance también plantea preguntas éticas sobre la autenticidad y el papel humano en la creación artística, pero, sin duda, marcará una nueva era en la evolución del arte y la música.',
        videourl:
          'https://youtube.com/playlist?list=PL4V2obbh3gnWgl6ZLIBfzgQiFC7cdviHw&si=mAaLzI8E8KTKd1ve',
        price: 80,
        coverImg: 'nacho.jpg',
        category: 'AI',
        location: 'Ukraine',
        locationImg: 'ukraine.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://nacho-ai.com/',
        youtube: 'https://www.youtube.com/@nuuttikataja666',
        facebook: 'https://www.facebook.com/freedomfightersofficial',
        instagram: 'https://www.instagram.com/djfreedomfighters',
        twitter: 'https://x.com/NotFreedomFight', 
        spotify:'https://open.spotify.com/artist/7Fmu1wTZGOG9b2w5qvM9XR'     
      },
      {
        title: 'Jazz Hive',
        description:"Jazz Hive te invita a un viaje audiovisual único a través del mundo del jazz, fusionando a la perfección melodías envolventes con escenarios cautivadores, desde acogedores destinos vacacionales y bulliciosas cafeterías hasta paisajes naturales serenos y mucho más.Déjate llevar por las melodías suaves y los ritmos vibrantes del jazz. Ya seas un apasionado del género o un recién llegado, nuestro canal te ofrece una amplia variedad de estilos que tocarán tu corazón y relajarán tu alma.Vive el jazz en diversas atmósferas. Nuestros videos temáticos te transportan a destinos festivos, sesiones en cafeterías que complementan tu café matutino y paisajes naturales que invitan a la tranquilidad, todo acompañado de la banda sonora perfecta para cada ocasión.",
        videourl:
          'https://youtube.com/playlist?list=PL6gtGRekJK-0RQ5dZ9BuIVdqgvvTFspwI&si=wxXkTg7ZHE4IeYq8',
        price: 80,
        coverImg: 'jazzhive.jpg',
        category: 'AI',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://rumble.com/c/c-7362811',
        youtube: 'https://www.youtube.com/@JazzHive',
        facebook: 'https://www.facebook.com/profile.php?id=6157260773650',
        instagram: 'https://www.instagram.com/thejazzhive/',
      },
    
]
   export default CardData39;