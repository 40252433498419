import Navbar from '../Navbar/Navbar';
import { useState, useEffect } from 'react';
import CardData32 from '../../data/CardData32';
import Card from '../Card';
import { Link } from 'react-router-dom';

// Function to shuffle array
const shuffleArray = (array) => {
  return array
    .map((item) => ({ item, sort: Math.random() })) // Assign random number to each item
    .sort((a, b) => a.sort - b.sort) // Sort based on random number
    .map(({ item }) => item); // Extract shuffled items
};

function Salsa() {
  const [searchQuery, setSearchQuery] = useState('');
  const [shuffledData, setShuffledData] = useState([]);

  useEffect(() => {
    // Generate IDs and shuffle the array only once when the component mounts
    const dataWithIds = CardData32.map((item, index) => ({
      ...item,
      id: `${item.title}-${index}`,
    }));

    setShuffledData(shuffleArray(dataWithIds));
  }, []); // Runs only once on mount

  const filteredData = shuffledData.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <Navbar />
      <h1 className="title2">Salsa pa Bailar</h1>
      <div className="search-bar">
        <input
          className="searchbar"
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Busca por Nombre del Artista Aqui...🔍︎🔍︎🔍︎🔍︎"
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link to='/categories'>
          <button
            style={{
              fontSize: '1rem',
              width: '200px',
              fontFamily: 'Henny Penny',
              background: 'goldenrod',
              color: 'black',
              borderRadius: '10px',
              marginTop: '20px',
            }}
          >
            Escoje el Tipo de Musica
          </button>
        </Link>
      </div>
      <h1 className="slidertext">←←Desliza para Navegar→→</h1>
      <section className="cards-list">
        {filteredData.map((item) => (
          <Card key={item.id} item={item} />
        ))}
      </section>
    </div>
  );
}

export default Salsa;
