const CardData13 = [
  {
    title: 'Grupo Firme',
    description:
      'Grupo Firme es una agrupación de música regional mexicana. Empezaron cómo grupo norteño, pero también han grabado con banda sinaloense. Ha recibido nominaciones para una variedad de programas que incluyen: Premios Juventud y Premio Lo Nuestro, resultando ganador en 2021 de este último con cinco distinciones.El fundador de la agrupación musical es el Choixcense de 32 años, Jairo Corrales el cual creó a la banda después de conocerse en un evento en la ciudad de Tijuana.',
    videourl: 'http://youtube.com/playlist?list=UUFlC8U0Uif2hF0FIgsSIIYQ',
    price: 50,
    coverImg: 'grupoFirme.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://grupofirmecorp.com/',
    youtube: 'https://www.youtube.com/channel/UCFlC8U0Uif2hF0FIgsSIIYQ',
    facebook: 'https://www.facebook.com/GrupoFirmeOficial',
    instagram: 'https://www.instagram.com/grupofirme/',
    twitter: 'https://twitter.com/GrupoFirme',
    spotify:
      'https://open.spotify.com/artist/1dKdetem2xEmjgvyymzytS?si=tWOpv5SYT7CkjsAVgCHHjw&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/9896010',
    apple: 'https://music.apple.com/mx/artist/grupo-firme/1086320682',
    soundcloud: 'https://soundcloud.com/grupofirme',
    tidal: 'https://listen.tidal.com/artist/7622411',
    wiki: 'https://en.wikipedia.org/wiki/Grupo_Firme',
    ticketmaster:
      'https://www.ticketmaster.com/grupo-firme-tickets/artist/2724935',
    tiktok: 'https://www.tiktok.com/@grupofirme?lang=es',
    napster: 'https://app.napster.com/artist/grupo-firme',
  },
  {
    title: 'Grupo Frontera',
    description:'Grupo Frontera es una banda musical estadounidense formada en Edinburg, Texas, en 2022. Está compuesta por: Adelaido «Payo» Solís III, Juan Javier Cantú, Julián Peña Jr., Alberto «Beto» Acosta, Carlos Guerrero y Brian Ortega.1​ Se especializan en música regional mexicana; específicamente en el subgénero de música norteña estilo regiomontana. Recibieron reconocimiento internacional en la década del 2020.2​3​ Su debut comenzó después de interpretar la canción «No se va» de la banda colombiana de Morat.',
    videourl:'https://youtube.com/playlist?list=PL_u4aYYWfB51LCb0oW9vpYGfCIiy4IL0G&si=ftQB_EoEjpZEWBTe',
    price: '$50',
    coverImg: 'frontera.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://grupo-frontera.com/',
    youtube: 'https://www.youtube.com/channel/UCKsN6xyJ2w8g7p4p9apXkYQ',
    facebook: 'https://www.facebook.com/fronteragrupo',
    instagram: 'https://www.instagram.com/grupofrontera',
    twitter: 'https://x.com/frontera_grupo',
    spotify: 'https://open.spotify.com/artist/6XkjpgcEsYab502Vr1bBeW',
    deezer: 'https://www.deezer.com/mx/artist/7201102',
    apple: 'https://music.apple.com/mx/artist/grupo-frontera/1613772487',
    soundcloud: 'https://soundcloud.com/grupofrontera',
    wiki: 'https://es.wikipedia.org/wiki/Grupo_Frontera',
    ticketmaster:'https://www.ticketmaster.com/grupo-frontera-tickets/artist/2942293',
    tiktok: 'https://www.tiktok.com/@grupofrontera',
    napster: 'https://music.amazon.com.mx/artists/B00QHEN5NC',
  },
  {
    title: 'Eden Muñoz',
    description:
      'Rodolfo Edén Muñoz Cantú (Los Mochis, Sinaloa, 25 de septiembre de 1990), es un cantante, compositor y productor mexicano de música regional mexicana. Muñoz es el menor de 4 hermanos del matrimonio entre Rodolfo Muñoz e Isidora Cantú quienes por azares del destino la vida los traslada a la ciudad de Mazatlán, Sinaloa, donde más adelante su crianza y entorno lo llevaría de lleno a la música.Desde muy pequeño Edén comenzó a dar muestra que había nacido para esto, ya que sin ninguna pena interpretaba canciones y le cambiaba la letra a estas sin saber que más adelante serviría de plataforma para lograr grandes composiciones que han marcado el rumbo de la música regional mexicana en los últimos tiempos..',
    videourl:
      'https://youtube.com/playlist?list=PLLlCI9n44muADESckd8b6oOL1cmfGLz35',
    coverImg: 'edenmunoz.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCJm7UPWbVhuNe3achnRi8iA',
    facebook: 'https://www.facebook.com/EdenMunozOficial/',
    instagram: 'https://www.facebook.com/EdenMunozOficial/',
    twitter: 'https://twitter.com/edenmunozof',
    spotify: 'https://open.spotify.com/artist/66ihevNkSYNzRAl44dx6jJ',
    deezer: 'https://www.deezer.com/mx/artist/4414006',
    apple: 'https://music.apple.com/mx/artist/eden-mu%C3%B1oz/383984059',
    soundcloud: 'https://soundcloud.com/edenmunoz-music',
    tidal: 'https://listen.tidal.com/artist/12062930',
    wiki: 'https://es.wikipedia.org/wiki/Ed%C3%A9n_Mu%C3%B1oz',
    ticketmaster:
      'https://www.ticketmaster.com/eden-munoz-tickets/artist/Z7r9jZa8yb',
    tiktok: 'https://www.tiktok.com/@edenmunoz1?lang=es',
    napster: 'https://web.napster.com/artist/eden-munoz-4',
  },
  {
    title: 'Chuy Lizarraga',
    description:
      'Chuy Lizarraga es “La cara alegre de la banda”, así es conocido ahora el cantante oriundo de Mazatlán Sinaloa, México; nacido el 9 de Noviembre de 1976 y con sus largos 10 años de carrera artística ahora incursiona en el medio musical como solista después de haber militado por varias agrupaciones del mismo estilo musical. .',
    videourl:
      'http://youtube.com/playlist?list=PLnQJvKNB7Plq5WdeHT05UmZbinWq7X9BD',
    price: 53,
    coverImg: 'chuylizzarraga.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/yochuylizarraga',
    youtube: 'https://www.youtube.com/channel/UCBiiKL2TfCpFVpwW-CJ0NCg',
    facebook: 'https://www.facebook.com/yochuylizarraga',
    instagram: 'https://www.instagram.com/yochuylizarraga/',
    twitter: 'https://twitter.com/yochuylizarraga',
    spotify:
      'https://open.spotify.com/artist/1DA8SLXtp8MMVpgaOWzMQr?si=aYLIs1_-Q8u0VWvPmNcDcw&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/324302',
    apple:
      'https://music.apple.com/mx/artist/chuy-liz%C3%A1rraga-y-su-banda-tierra-sinaloense/260821242',
    soundcloud: 'https://soundcloud.com/user-983297265',
    tidal: 'https://listen.tidal.com/artist/8197769',
    wiki: 'https://www.buenamusica.com/chuy-lizarraga/biografia',
    ticketmaster:
      'https://www.ticketmaster.com/chuy-lizarraga-tickets/artist/1665983',
    tiktok: 'https://www.tiktok.com/@chuylizarraga13?lang=es',
    napster: 'https://web.napster.com/artist/art.54718648',
  },
  {
    title: 'Banda MS',
    description:
      'La Banda Sinaloense MS de Sergio Lizárraga, mejor conocida como Banda MS, es una de las agrupaciones más emblemáticas y exitosas de la música de banda sinaloense. Originaria de Mazatlán, Sinaloa, la banda fue fundada en 2003 por un grupo de jóvenes músicos liderados por Sergio Lizárraga. Desde sus inicios, Banda MS ha logrado un crecimiento notable en la escena musical, destacándose por su estilo único que fusiona la tradición de la música de banda con toques modernos, lo que le ha permitido conquistar tanto a los fanáticos del género como a nuevos oyentes. Su sonido característico se basa en poderosas trompetas, tubas y clarinetes, combinados con un talento vocal excepcional, que han marcado la diferencia en sus producciones. Con una trayectoria de más de dos décadas, la banda ha logrado una serie de éxitos que se han posicionado en los primeros lugares de las listas de popularidad, ganando numerosos premios y reconocimientos dentro de la industria musical. Su música, que abarca temas de amor, desamor, y experiencias de vida, ha tocado los corazones de miles de personas a lo largo y ancho del mundo, consolidándose como uno de los referentes más importantes de la música mexicana contemporánea. Banda MS continúa en constante evolución, con presentaciones en vivo que se llenan de energía y emoción, y una base de seguidores que sigue creciendo a cada paso.',
    videourl:
      'http://youtube.com/playlist?list=PLQDuTkZkYAFkYOzl5ba0PIarAXME9D0jI',
    price: 50,
    coverImg: 'Banda-MS.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bandams.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCV6ZlWpVitOcQvaL_RFgu5w',
    facebook: 'https://www.facebook.com/BANDAMSOFICIAL',
    instagram: 'https://www.instagram.com/bandamsoficial/',
    twitter: 'https://twitter.com/BANDA_MS',
    spotify:
      'https://open.spotify.com/user/sergioliz%C3%A1rraga?si=Lo0706DmRWCdd17X7GHjWA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/50683152',
    apple:
      'https://music.apple.com/mx/artist/banda-ms-de-sergio-liz%C3%A1rraga/413048014',
    soundcloud: 'https://soundcloud.com/bandasinaloensemsdesergiolizarraga',
    tidal: 'https://listen.tidal.com/artist/9097771',
    wiki: 'https://es.wikipedia.org/wiki/Banda_MS',
    ticketmaster: 'https://www.ticketmaster.com/artist/1571992',
    tiktok: 'https://www.tiktok.com/@banda_ms_fans_tr',
    napster:
      'https://web.napster.com/artist/banda-sinaloense-ms-de-sergio-lizarraga',
  },
  {
    title: 'Carin Leon',
    description:
      'Óscar Armando Díaz De León Huez, mejor conocido en el medio musical como Carín León, es un cantante, guitarrista y compositor mexicano. Nació en Hermosillo, Sonora, México, el 26 de julio de 1989. El cantante comenzó su carrera formando parte de distintas agrupaciones, tales como Los Reales y Grupo Arranke. En el 2018 comenzó con su carrera como solista, logrando un amplio reconocimiento en la escena en poco tiempo.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nJNKtjy1IdtjM71VR780T24y2MetYuNSg',
    coverImg: 'carinleon.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCywpKTAjNjHv53c0TX3K_KQ',
    facebook: 'https://www.facebook.com/carinleonoficial',
    instagram: 'https://www.instagram.com/carinleonoficial/',
    twitter: 'https://twitter.com/carinleonofi',
    spotify: 'https://open.spotify.com/artist/66ihevNkSYNzRAl44dx6jJ',
    deezer: 'https://www.deezer.com/mx/artist/14539467',
    apple: 'https://music.apple.com/mx/artist/carin-leon/1370196486',
    soundcloud: 'https://soundcloud.com/carinleon',
    tidal: 'https://listen.tidal.com/artist/9739372',
    wiki:
      'https://es.wikipedia.org/w/index.php?search=Carin+Leon&title=Especial%3ABuscar&go=Ir&ns0=1&ns100=1&ns104=1',
    ticketmaster: 'https://www.ticketmaster.com/calibre-50-tickets/artist/183',
    tiktok: 'https://www.tiktok.com/@oficialcalibre50?lang=es',
    napster: 'https://web.napster.com/artist/calibre-50',
  },
  {
    title: 'La Arrolladora Banda El Limón de René Camacho',
    description:
      'La Arrolladora Banda El Limón de René Camacho, o simplemente La Arrolladora, es una agrupación musical mexicana de Banda sinaloense. La agrupación sinaloense ha lanzado al mercado más de 23 álbumes, 19 de estudio y 4 en vivo.\nRené Camacho crea su propia banda en el año de 1997 tras tener conflictos con Salvador Lizárraga quien fuera compañero de una banda a la que pertenecían, y es aquí cuando se separan y cada uno por su lado decide llamar a su banda con un nombre diferente pero agregándole El Limón y por eso existen La Original Banda El Limón de Salvador Lizárraga y La Arrolladora Banda El Limón de René Camacho. Como ambas bandas son muy populares, a veces con el mismo público, y tienen nombres completos similares, la de René Camacho es referida simplemente como La Arrolladora para evitar confusiones.',
    videourl:
      'https://youtube.com/playlist?list=PLt2gAGc89Q6pQEyU0TNLn86TIKIsjdueG&si=3jvqhO50aTCgskUe',
    price: '$50',
    coverImg: 'arroladora.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.laarrolladora.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCUcO_QXDloLSiGNhxa5XWCA',
    facebook: 'https://www.facebook.com/arrolladoraoficial',
    instagram: 'https://www.instagram.com/laar.rolladora_ellimon_oficial/',
    twitter: 'https://twitter.com/Arrolladora',
    spotify: 'https://open.spotify.com/artist/5bSfBBCxY8QAk4Pifveisz',
    deezer: 'https://www.deezer.com/en/artist/345055',
    apple:
      'https://music.apple.com/mx/artist/la-arrolladora-banda-el-lim%C3%B3n-de-ren%C3%A9-camacho/272094455',
    soundcloud:
      'https://soundcloud.com/la-arrolladora-banda-el-limon-de-rene-camacho',
    tidal: 'https://listen.tidal.com/artist/4311759',
    wiki: 'https://es.wikipedia.org/wiki/La_Arrolladora_Banda_El_Lim%C3%B3n',
    ticketmaster:
      'https://www.ticketmaster.com/la-arrolladora-banda-limon-tickets/artist/1323577',
    tiktok: 'https://www.tiktok.com/@arrolladoraoficial',
    napster:
      'https://music.amazon.com/artists/B00130F65A/la-arrolladora-banda-el-lim%C3%B3n-de-ren%C3%A9-camacho',
  },
  {
    title: 'Pancho Barraza',
    description:
      'Pancho Barraza nació en Villa Union, municipio de Mazatlan, Sinaloa el 17 DE JUNIO DE 1961. Desde que era niño demostró inquietudes por la música, adquirió experiencia participando en diversos concursos. Estudio la carrera de profesor de música en la Bénemerita Escuela Normal de Sinaloa y ejerció como tal en una secundaría del poblado Villa Unión hasta los 22 años cuando se integró como bajista al grupo Karisma y así mismo al coro de la iglesia de dicho pueblo.',
    videourl: 'http://youtube.com/playlist?list=UU182E6DfqeGhPcyo-6N8xig',
    price: 50,
    coverImg: 'PanchoBarraza.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://en.wikipedia.org/wiki/Pancho_Barraza',
    youtube: 'https://www.youtube.com/c/PanchoBarrazaOficial',
    facebook: 'https://www.facebook.com/pbarrazaoficial/',
    instagram: 'https://www.instagram.com/panchobarrazaoficial/',
    twitter: 'https://twitter.com/panchobarraza20',
    spotify: 'https://open.spotify.com/artist/5dmU7FrmtbQaSzIvGsE4Jp',
    deezer: 'https://www.deezer.com/mx/artist/98005',
    apple: 'https://music.apple.com/mx/artist/pancho-barraza/251493410',
    soundcloud: 'https://soundcloud.com/pancho-barraza-official',
    tidal: 'https://listen.tidal.com/artist/3715927',
    wiki: 'https://en.wikipedia.org/wiki/Pancho_Barraza',
    ticketmaster:
      'https://www.ticketmaster.com/pancho-barraza-tickets/artist/784419',
    tiktok: 'https://www.tiktok.com/@panchobarrazaoficial?lang=es',
    napster: 'https://web.napster.com/artist/pancho-barraza',
  },
  {
    title: ' El Komander',
    description:
      'José Alfredo Ríos Meza (Culiacán, Sinaloa, 29 de abril de 1983), comúnmente conocido como su apodo El Komander, es un cantante y compositor del género regional mexicano, especializado en los estilos de Pop, norteño sinaloense, norteño con tuba, y mariachi. Es especialmente famoso por sus narcocorridos y su papel en el llamado «movimiento alterado».',
    videourl:
      'https://youtube.com/playlist?list=UULF9p4UQmMMmg_9cR2vuRX3eQ&si=dHsjg4kCq3RxUFoN',
    price: 50,
    coverImg: 'komander.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.musica.com/letras.asp?letras=33343',
    youtube: 'https://www.youtube.com/ElDiarioDeElKomander',
    facebook: 'https://www.facebook.com/El.Komander.Oficial',
    instagram: 'https://www.instagram.com/soyelkomander1',
    twitter: 'https://twitter.com/el_komander1',
    spotify: 'https://open.spotify.com/artist/2wC90WSKQd0BvdxJZ0mObr',
    deezer: 'https://www.deezer.com/mx/artist/363112',
    apple: 'https://music.apple.com/mx/artist/el-komander/319077164',
    soundcloud: 'https://soundcloud.com/el-komander-official',
    tidal: 'https://listen.tidal.com/artist/3909187',
    wiki: 'https://es.wikipedia.org/wiki/El_Komander',
    ticketmaster:
      'https://www.ticketmaster.com.mx/el-komander-tickets/artist/1518582',
    tiktok: 'https://www.tiktok.com/@alfreditokomander?lang=es',
    napster: 'https://music.amazon.com/artists/B002C91IZS/el-komander',
  },
  {
    title: 'Larry Hernandez',
    description:
      'Larry Hernández (10 de marzo de 1977, Los Ángeles, California)es un cantautor y personalidad televisiva estadounidense conocido por su trabajo en el género musical regional mexicano; específicamente en los estilos de banda sinaloense y norteño sinaloense, y es especialmente famoso por sus corridos. La mayor influencia e ídolo de Hernández es el fallecido Chalino Sánchez, pero también siente admiración por muchos artistas y sus influencias musicales son muy amplias.Hernández vivió en Los Ángeles hasta los cuatro años cuando su familia se mudó a Culiacán, Sinaloa, México. Allí pasó la mayor parte de su adolescencia antes de iniciar su carrera musical. Actualmente vive en West Covina, California..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mgD_v1tNhe_OBFiiJOmmdNxYXbuCXZDGM&si=csHFavhLaOo1tb5h',
    price: 50,
    coverImg: 'larryhernandez.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://larryhernandezoficial.com',
    youtube: 'https://www.youtube.com/channel/UCvZPtyFvV6bKywbA6z16IyQ',
    facebook: 'https://www.facebook.com/larry.hernandez',
    instagram: 'https://www.instagram.com/larrynaticos/',
    twitter: 'https://twitter.com/larryhernandez1',
    spotify: 'https://open.spotify.com/artist/6djNDzSg08mnh2Ayoh6Ovn',
    deezer: 'https://www.deezer.com/en/artist/393600',
    apple: 'https://music.apple.com/mx/artist/larry-hern%C3%A1ndez/268935717',
    soundcloud: 'https://soundcloud.com/larry-hernandez-official',
    tidal: 'https://listen.tidal.com/artist/3959932',
    wiki: 'https://es.wikipedia.org/wiki/Larry_Hern%C3%A1ndez',
    ticketmaster:
      'https://www.ticketmaster.com/larry-hernandez-tickets/artist/1449940',
    tiktok: 'https://www.tiktok.com/@larrymania?lang=es',
    napster: 'https://music.amazon.com/artists/B004X97W4Q/larry-hern%C3%A1ndez',
  },
  {
    title: 'Julio Preciado',
    description:
      'Julio César Preciado Quevedo (Mazatlán, Sinaloa, México, 1 de diciembre de 1966) es un cantante mexicano de banda sinaloense que formó parte de la banda más representativa de este género musical la Banda del Recodo de don Cruz Lizárraga como vocalista de 1991 a 1998 siguiendo posteriormente una carrera en solitario con la Banda Perla del Pacífico. .',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lANuTCU3POCJ6nmDBkuuQyQq6Z1F17ohE',
    price: 50,
    coverImg: 'JulioPreciado.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Julio_Preciado',
    youtube: 'https://www.youtube.com/channel/UC1X--Zjk2esCKjkm5jtM_VA',
    facebook: 'https://www.facebook.com/Julio.C.Preciado/',
    instagram: 'https://www.instagram.com/juliopreciado_/',
    twitter: 'https://twitter.com/Julio_Preciado_',
    spotify: 'https://open.spotify.com/artist/7yUfXtRIpBZK5bggQsQohI',
    deezer: 'https://www.deezer.com/mx/artist/309216',
    apple: 'https://music.apple.com/mx/artist/julio-preciado/2357835',
    soundcloud: 'https://soundcloud.com/julio-preciado-official',
    tidal: 'https://listen.tidal.com/artist/3624039',
    wiki: 'https://es.wikipedia.org/wiki/Julio_Preciado',
    ticketmaster:
      'https://www.ticketmaster.com/julio-preciado-tickets/artist/796690',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Julio%20Preciado&t=1655491640150',
    napster: 'https://web.napster.com/artist/julio-preciado',
  },
  {
    title: 'Julion Alvarez',
    description:
      'Julio César Álvarez Montelongo, conocido como Julion Álvarez (La Concordia, Chiapas, 11 de abril de 1983), es un cantante mexicano del género regional mexicano.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k1eXQS6UHsS3uTPtv-NuGnIQF5QQKui_k',
    price: '$50',
    coverImg: 'julion.jpeg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://julionalvarez.net/',
    youtube: 'https://www.youtube.com/channel/UCbh61ZOdAzM_w2U0tS2XNJw',
    facebook: 'https://www.facebook.com/lospasosdejulion',
    instagram: 'https://www.instagram.com/lospasosdejulion/',
    twitter: 'https://twitter.com/julionalvarez',
    spotify: 'https://open.spotify.com/artist/1bfl0AU8SqmLkElptOprhC',
    deezer: 'https://www.deezer.com/mx/artist/363128',
    apple:
      'https://music.apple.com/us/artist/juli%C3%B3n-%C3%A1lvarez-y-su-norte%C3%B1o-banda/266966568',
    soundcloud: 'https://soundcloud.com/julionalvarez',
    tidal: 'https://listen.tidal.com/artist/3997775',
    wiki: 'https://es.wikipedia.org/wiki/Juli%C3%B3n_%C3%81lvarez',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Julion+Alvarez',
    tiktok: 'https://www.tiktok.com/@julionalvarez_oficial',
    napster: 'https://us.napster.com/artist/julion-alvarez-y-su-norteno-banda',
  },
  {
    title: 'La Adictiva',
    description:
      'La Adictiva, anteriormente conocida como La Adictiva Banda San José de Mesillas, es una banda sinaloense de origen mexicano fundada en el año de 1990 en el poblado de San José de Mesillas, en el municipio de Concordia, en el estado mexicano de Sinaloa, México.Desde su incorporación a la discográfica Sony Music en el año 2010, la agrupación ha grabado varios temas con éxito a nivel nacional, convirtiéndose en una de las bandas sinaloenses con mayor difusión.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWs5sPNMUDlALELK9EPin3X',
    price: 50,
    coverImg: 'LaAdictiva.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://laadictiva.com/',
    youtube: 'https://www.youtube.com/channel/UCNkwFuDn6zkt0Bpjg1BYVXw',
    facebook: 'https://www.facebook.com/adictivaoficial',
    instagram: 'https://www.instagram.com/adictivaoficial/',
    twitter: 'https://twitter.com/adictivaoficial',
    spotify: 'https://open.spotify.com/artist/49EE6lVLgU8sp7dFgPshgM',
    deezer: 'https://www.deezer.com/mx/artist/14803977',
    apple: 'https://music.apple.com/mx/artist/la-adictiva/1161844446',
    soundcloud: 'https://soundcloud.com/adictivaoficial',
    tidal: 'https://listen.tidal.com/artist/3698386',
    wiki: 'https://es.wikipedia.org/wiki/La_Adictiva.',
    ticketmaster:
      'https://www.ticketmaster.com/banda-la-adictiva-tickets/artist/2822025',
    tiktok: 'https://www.tiktok.com/@laadictivaoficial?lang=es',
    napster: 'https://web.napster.com/artist/art.111973912',
  },
  {
    title: 'Banda Carnaval',
    description:
      "Banda Carnaval' is a Mexican Latin Grammy nominated Banda from Mazatlán, Sinaloa, Mexico. The banda was named after “El Carnaval de Mazatlán” which is the biggest event that takes place every year in their hometown of Mazatlán.[1] The Banda was formed by Jesús Tirado Castañeda in 2001.",
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVrwbI_67jocRckmRgzeCSz',
    price: 50,
    coverImg: 'carnaval.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://bandacarnaval.co/',
    youtube: 'https://www.youtube.com/channel/UCKrEo5CpSJsailZ21nDVEuA',
    facebook: 'https://www.facebook.com/BandaCarnavalOfficial/',
    instagram: 'https://www.instagram.com/bandacarnavaloficial/',
    twitter: 'https://twitter.com/_bandacarnaval',
    spotify: 'https://open.spotify.com/artist/6LOvxDn71T0wWhCDNcXcUj',
    deezer: 'https://www.deezer.com/mx/artist/324415',
    apple: 'https://music.apple.com/mx/artist/banda-carnaval/310080082',
    soundcloud: 'https://soundcloud.com/banda-carnaval',
    tidal: 'https://listen.tidal.com/artist/3909165',
    wiki: 'https://en.wikipedia.org/wiki/Banda_Carnaval#',
    ticketmaster:
      'https://www.ticketmaster.com.mx/banda-carnaval-boletos/artist/1831823',
    tiktok: 'https://www.tiktok.com/@bandacarnavalof?lang=es',
    napster: 'https://app.napster.com/artist/banda-carnaval',
  },

  {
    title: 'Calibre 50',
    description:
      'Calibre 50 es una agrupación de música regional mexicana especializada en el estilo de norteño-banda y creada en la ciudad de Mazatlán, Sinaloa en el año de 2010.',
    videourl:
      'http://youtube.com/playlist?list=PLqiykNFKiNhiewntt733rpHKO7o--m5Mc',
    price: 50,
    coverImg: 'calibre50.jpg',
    category: 'Norteño-Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://calibre50.co/',
    youtube: 'https://www.youtube.com/channel/UC7r3fSrFc31cs9pJRNgGWiQ',
    facebook: 'https://www.facebook.com/Calibre50Musica/',
    instagram: 'https://www.instagram.com/calibre50oficial/',
    twitter: 'https://twitter.com/Calibre50',
    spotify:
      'https://open.spotify.com/artist/4jogXSSvlyMkODGSZ2wc2P?si=34lzCyiXSW-_liVnJ1nCfQ&nd=1',
    deezer: 'https://www.deezer.com/search/calibre%2050v',
    apple: 'https://music.apple.com/mx/artist/calibre-50/397237634',
    soundcloud: 'https://soundcloud.com/calibre-50-official',
    tidal: 'https://listen.tidal.com/artist/4330329',
    wiki: 'https://es.wikipedia.org/wiki/Calibre_50',
    ticketmaster:
      'https://www.ticketmaster.com/calibre-50-tickets/artist/1831822',
    tiktok: 'https://www.tiktok.com/@oficialcalibre50?lang=es',
    napster: 'https://web.napster.com/artist/calibre-50',
  },
  {
    title: 'Marca-MP',
    description:
      'Marca MP es una agrupación de música regional mexicana, principalmente de banda, mariachi y ranchera. Incursionaron al mundo de la música profesionalmente en 2019 con su sencillo “El Güero”, que interpretaron al lado de Grupo Firme. Se ganaron al público desde su primer sencillo, muestra de ello, es que “El Güero” permaneció en la cima de la lista como el video grupero más visto de ese año.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lbDCxSZ88e4zNta116vD4z3LCWJovkhmA',
    price: 50,
    coverImg: 'marca-mp.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: ' https://www.facebook.com/grupomarcamp',
    youtube: 'https://www.youtube.com/c/MarcaMP​',
    facebook: ' https://www.facebook.com/grupomarcamp/​',
    instagram: 'https://instagram.com/mmp_oficial​',
    twitter: 'https://twitter.com/marcampoficial',
    spotify: 'https://open.spotify.com/artist/44mEtidu0VdRkIqO4IbkNa',
    deezer: 'https://www.deezer.com/mx/artist/61384072',
    apple: 'https://music.apple.com/mx/artist/marca-mp/1493050737',
    soundcloud: 'https://soundcloud.com/mmp_oficial',
    tidal: 'https://listen.tidal.com/artist/15257396',
    wiki:
      'https://popnable.com/mexico/artists/52383-marca-mp/biography-and-facts#:~:text=Marca%20Mp%20Wiki%20Latin%20group%20with%20a%20notable,prominently%20performed%20in%20Mexico%20and%20the%20United%20States.',
    ticketmaster:
      'https://www.ticketmaster.com/marca-mp-tickets/artist/2802450',
    tiktok: 'https://www.tiktok.com/@mmp.oficial?lang=es',
    napster: 'https://web.napster.com/artist/marca-mp',
  },
  {
    title: 'Gerardo Ortiz',
    description:
      'Gerardo Ortiz Medina es un cantante y compositor mexicano-estadounidense de música regional mexicana, especializado en los estilos de banda, norteño, norteño-banda, sierreño, sierreño-banda y mariachi. Es especialmente famoso por sus corridos progresivos.',
    videourl:
      'http://youtube.com/playlist?list=PLByjh6DfxKTKdej2wbJqw6UmA2KHszPyG',
    price: 50,
    coverImg: 'gerardoOrtiz.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.comerecallado.com/',
    youtube: 'https://www.youtube.com/channel/UCfxLuDIVfwArsz5waYMMLDQ',
    facebook: 'https://www.facebook.com/GerardoOrtizNet',
    instagram: 'https://www.instagram.com/gerardoortizoficial/',
    twitter: 'https://twitter.com/gerardoortiznet',
    spotify: 'https://GerardoOrtiz.lnk.to/ElComando... ',
    deezer: 'https://www.deezer.com/mx/artist/468783',
    apple: 'https://music.apple.com/mx/artist/gerardo-ortiz/339929849',
    soundcloud: 'https://soundcloud.com/gerardoortizofficial',
    tidal: 'https://listen.tidal.com/artist/6174510',
    wiki: 'https://es.wikipedia.org/wiki/Gerardo_Ortiz',
    ticketmaster:
      'https://www.ticketmaster.com/gerardo-ortiz-tickets/artist/1386548',
    tiktok: 'https://www.tiktok.com/@....gerardoortizoficial?lang=es',
    napster: 'https://web.napster.com/artist/gerardo-ortiz',
  },
  {
    title: 'Neto Bernal',
    description:
      'Ernesto Bernal Contreras, mejor conocido como Neto Bernal, es un cantante de música regional, procedente de Mexicali, Baja California, México. El joven, que nació el 1 de octubre de 1997, comparte junto con su madre el origen en la ciudad fronteriza, Mexicali, mientras que su padre viene de Sonora. El artista, de tan sólo 19 años, creció allí junto con su hermano menor, Alexis Bernal.',
    videourl:
      'http://youtube.com/playlist?list=PLaSnKw4FFePPPOXaDVDk3SObo2PbAeqbp',
    price: 50,
    coverImg: 'NetoBernal.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UC0tWKR_TBob21vJTEO67UfA',
    facebook: 'https://www.facebook.com/NetoBernalOficial',
    instagram: 'https://www.instagram.com/netobernaloficial/?hl=en',
    twitter: 'https://twitter.com/elnetillobernal',
    spotify:
      'https://open.spotify.com/artist/0X8PwlFMDK85fIadwq3cA5?si=wQEx4ri9Q_yhkyqLdv1t5w&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/10693157',
    apple: 'https://music.apple.com/mx/artist/neto-bernal/1133791209',
    soundcloud: 'https://soundcloud.com/neto-bernal',
    tidal: 'https://listen.tidal.com/artist/7990895',
    wiki:
      'https://laletrade.com/biografias/regional-mexicano/18635-neto-bernal',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@netobernal?lang=es',
    napster: 'https://web.napster.com/artist/neto-bernal',
  },

  {
    title: 'Horacio Palencia',
    description:
      'Horacio Palencia es uno de los compositores más importantes en la actualidad del regional mexicano, donde suenan más de mil canciones de su autoría.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nrVYMBOp_u8-iH8TnI2zvmsn2sH2H-Vg8',
    price: 50,
    coverImg: 'HoracioPalencia.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/HoracioPalencia',
    youtube: 'https://www.youtube.com/channel/UCL9JY0TLQXbXM825DLTqHjw',
    facebook: 'https://www.facebook.com/HoracioPalencia',
    instagram: 'https://www.instagram.com/horaciopalencia/',
    twitter: 'https://twitter.com/horaciopalencia',
    spotify: 'https://open.spotify.com/artist/7arK8QUX7X61NIkChn9no2',
    deezer: 'https://www.deezer.com/mx/artist/1685815',
    apple: 'https://music.apple.com/mx/artist/horacio-palencia/213004989',
    soundcloud: 'https://soundcloud.com/horacio-palencia',
    wiki: 'https://es.wikipedia.org/wiki/Todo_depende_de_ti',
    ticketmaster:
      'https://www.ticketmaster.com/horacio-palencia-tickets/artist/1968743',
    tiktok: 'https://www.tiktok.com/@horaciopalenciatiktok?lang=es',
    napster: 'https://web.napster.com/artist/art.58205375',
  },
  {
    title: 'Jesse Uribe',
    description:
      ' Yesid Eduardo Uribe Ordóñez (Bucaramanga, 22 de marzo de 1987), más conocido como Jessi Uribe, es un cantante y músico colombiano, reconocido principalmente por su participación en los realities La Voz Colombia y A otro nivel del Canal Caracol..',
    videourl:
      'http://youtube.com/playlist?list=PL4UtXzxnvDJ2LHj0DgQc4jh9UQI-j9kTM',
    price: 50,
    coverImg: 'JesseUribe.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.jessiuribe.com/',
    youtube: 'https://www.youtube.com/jessiuribe3',
    facebook: 'https://www.facebook.com/jessiuribemusic',
    instagram: 'https://www.instagram.com/jessiuribe3/',
    twitter: 'https://twitter.com/Jessiuribe3',
    spotify: 'https://open.spotify.com/artist/3SN7I8KV2qBwTCZ4aNDcbS',
    deezer: 'https://www.deezer.com/mx/artist/7979540',
    apple: 'https://music.apple.com/mx/artist/jessi-uribe/998331667',
    soundcloud: 'https://soundcloud.com/jessiuribeofficial',
    tidal: 'https://listen.tidal.com/artist/7454431',
    wiki: 'https://es.wikipedia.org/wiki/Jessi_Uribe',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@jessiuribeoficial?lang=es',
    napster: 'https://web.napster.com/artist/jessi-uribe',
  },

  {
    title: 'Luis Angel-El Flaco',
    description:
      'Luis Ángel Franco Rivera mejor conocido como Luis Angel El flaco” es un famoso cantante de música de banda y regional mexicano que ha logrado millones de fans por su gran talento vocal. Luis Angel -El flaco” nació el 7 de marzo de 1987 en Mazatlán Sinaloa y también es muy reconocido por haber sido vocalista de La Banda Los Recoditos por más de 16 años..',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_m_Jv-ZG4bHK1gIJjgHqlUjZQcLBCxnla8',
    price: 50,
    coverImg: 'ElFlaco.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/LuisAngelElFlaco/',
    youtube: 'https://www.youtube.com/channel/UCdhfjV6C97UkLgOYonsj3Zw',
    facebook: 'https://www.facebook.com/LuisAngelElFlaco/',
    instagram: 'https://www.instagram.com/luisangelelflaco/',
    twitter: 'https://twitter.com/luisangelelfla',
    spotify:
      'https://open.spotify.com/artist/4kJ2OBSNasUA4yOT5NCfCl?si=mkd-8murRnuOfzdqr9XL_Q&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/85216072',
    apple: 'https://music.apple.com/mx/artist/luis-angel-el-flaco/1497949275',
    soundcloud: 'https://soundcloud.com/luisangelelflaco',
    tidal: '',
    wiki:
      'https://www.grmediostv.com/2022/06/15/siguen-los-exitos-para-luis-angel-el-flaco/',
    ticketmaster:
      'https://www.ticketmaster.com.mx/luis-angel-el-flaco-boletos/artist/2771331',
    tiktok: 'https://www.tiktok.com/@luisangelelflaco1?lang=es',
    napster: 'https://web.napster.com/artist/luis-angel-el-flaco',
  },
  {
    title: 'Espinoza Paz',
    description:
      'Isidro Chávez Espinoza, conocido artísticamente como Espinoza Paz (29 de octubre de 1981, Angostura, Sinaloa), es un músico y cantautor mexicano, especializado en los estilos de banda, norteño, mariachi, duranguense y country en español.',
    videourl:
      'http://youtube.com/playlist?list=PL0bZXeO0KLMMf6zz6etoYNEWEijQ9ZoLA',
    price: 36,
    coverImg: 'espinoza.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.espinozapaz.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCSN8m0p-1RNoNljhNawQRhA',
    facebook: 'https://www.facebook.com/EspinozaPaz',
    instagram: 'https://www.instagram.com/espinozapaz/',
    twitter: 'https://twitter.com/EspinozaOficial',
    spotify: 'https://open.spotify.com/artist/01rgao9OzfBm2BOHWJpi1Y',
    deezer: 'https://www.deezer.com/mx/artist/363114',
    apple: 'https://music.apple.com/mx/artist/espinoza-paz/151079916',
    soundcloud: 'https://web.napster.com/artist/espinoza-paz',
    tidal: 'https://listen.tidal.com/artist/3909164',
    wiki: 'https://es.wikipedia.org/wiki/Espinoza_Paz',
    ticketmaster:
      'https://www.ticketmaster.com/espinoza-paz-tickets/artist/1388282',
    tiktok: 'https://www.tiktok.com/@espinozapaz?lang=es',
    napster: 'https://web.napster.com/artist/espinoza-paz',
  },
  {
    title: 'Banda La Costeña',
    description:
      'Fue fundada por Don Ramón López Alvarado en 1950. “El escultor del viento” como también es llamado era originario de Siqueros, Sinaloa. La agrupación ha grabado más de 290 discos y ha acompañado a grandes artistas en los escenarios como Joan Sebastian, Antonio Anguilar, Chalino Sánchez, Lalo Mora, Chayito Valdés y Paquita La del Barrio. Actualmente es liderada por Ulises y Edgardo López Carvajal, dos de los hijos de Don Ramón, y la tercera generación de la familia musical también está presente desde hace varios años en las tarolas y segunda voz, Édgar. .',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_m-GwHGwlcZ1QxWCJ1IJiIXGoi2eXdOVT8',
    price: '$50',
    coverImg: 'laCosteña.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.bandalacostena.com/',
    youtube: 'https://www.youtube.com/channel/UCLWUnirXsFeveJZhZaul7Pw',
    facebook: 'https://www.facebook.com/lacostenaoficial',
    instagram: 'https://www.instagram.com/banda_lacostena/',
    twitter: 'https://twitter.com/lacostena_rla',
    spotify: 'https://open.spotify.com/artist/1r8tUG15NMJEj1j5NynES7',
    deezer: 'https://www.deezer.com/mx/artist/96648',
    apple:
      'https://music.apple.com/mx/artist/banda-sinaloense-la-coste%C3%B1a/209798749',
    soundcloud: 'https://soundcloud.com/bandalacostenaofficial',
    tidal: 'https://listen.tidal.com/artist/3665785',
    wiki:
      'https://banda.fandom.com/es/wiki/Banda_La_Coste%C3%B1a_De_Ram%C3%B3n_L%C3%B3pez_Alvarado',
    ticketmaster:
      'https://www.ticketmaster.com/banda-la-costena-tickets/artist/778883',
    tiktok: 'https://www.tiktok.com/@bandaloscostenos?lang=es',
    napster: 'https://web.napster.com/artist/art.14935644',
  },
  {
    title: 'Banda K-Paz de La Sierra',
    description:
      'K-Paz De La Sierra Es una agrupación de música regional mexicana, especializada en el estilo duranguense. Se formó en Chicago, Illinois, Estados Unidos. El grupo se formó en diciembre de 2002 por Sergio Gómez Sánchez y algunos miembros de la salida de Grupo Montéz de Durango. .',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mdHAAEqwBtH4suptwloOTdXBVm59Ir93Y',
    price: 50,
    coverImg: 'k-paz.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/K-Paz_de_la_Sierra',
    youtube: 'https://www.youtube.com/channel/UC9r4DpG2KDMb8FhTLduDprw',
    facebook: 'https://www.facebook.com/kpaz.cluboficialMexico/',
    instagram: 'https://www.instagram.com/kpazoficial/',
    twitter: 'https://twitter.com/oficialkpaz',
    spotify: 'https://open.spotify.com/artist/1TCSet7pGZCDkcXCOzH359',
    deezer: 'https://www.deezer.com/mx/artist/363109',
    apple: 'https://music.apple.com/mx/artist/k-paz-de-la-sierra/158070470',
    soundcloud: 'https://soundcloud.com/k-paz-de-la-sierra-official',
    tidal: 'https://listen.tidal.com/artist/3675117',
    wiki: 'https://es.wikipedia.org/wiki/K-Paz_de_la_Sierra',
    ticketmaster:
      'https://www.ticketmaster.com/k-paz-de-la-sierra-tickets/artist/1287521',
    tiktok: 'https://www.tiktok.com/@_kpaz?lang=es',
    napster: 'https://web.napster.com/artist/k-paz-de-la-sierra',
  },
  {
    title: 'Banda Machos',
    description:
      'Banda Machos es un grupo musical procedente de Villa Corona, Jalisco, México de technobanda, quebradita, cumbia y ranchera. .',
    videourl: 'http://youtube.com/playlist?list=PLE3BECA3361DDEE56',
    price: 50,
    coverImg: 'bandamachos.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Banda_Machos',
    youtube: 'https://www.youtube.com/channel/UCn5Us84QezulZfPB3aVf3uA',
    facebook: 'https://www.facebook.com/BandaMachos',
    instagram: 'https://www.instagram.com/BandaMachosOficial/',
    twitter: 'https://twitter.com/BandaMachos',
    spotify:
      'https://open.spotify.com/artist/7MyUjj79oHy7I8RocrtzZ2?si=7KvsvSZtSRSAI9SXVOi9OA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/185486',
    apple: 'https://music.apple.com/mx/artist/banda-machos/2138000',
    soundcloud: 'https://soundcloud.com/banda-machos-official',
    tidal: 'https://listen.tidal.com/artist/11965',
    wiki: 'https://es.wikipedia.org/wiki/Banda_Machos',
    ticketmaster:
      'https://www.ticketmaster.com/banda-machos-tickets/artist/760120',
    tiktok: 'https://www.tiktok.com/@bandamachoslareina?lang=es',
    napster: 'https://web.napster.com/artist/banda-machos',
  },
  {
    title: 'Banda El Recodo de Don Cruz Lizarraga',
    description:
      'Banda Sinaloense El Recodo de Don Cruz Lizárraga es una banda sinaloense formada en El Recodo, un pueblo en el Municipio de Mazatlán, Sinaloa, en 1938 por Don Cruz Lizárraga, la cual se puede considerar como la pionera en la divulgación del género, que ya se venía gestando desde décadas atrás, por lo que se le conoce como La Madre de Todas las Bandas. En un principio, se dedicó a tocar corridos, sones, polkas y marchas, pero con el tiempo introdujo un nuevo estilo, empleando la tambora como instrumento principal y generando así piezas más populares. Ha tocado al lado de personajes de la talla de José Alfredo Jiménez, Lola Beltrán y Juan Gabriel. Actualmente cuenta con gran reconocimiento internacional, ha grabado más de 180 álbumes y ha vendido más de 20 millones de discos, llegando su música a los 5 continentes. Actualmente la banda es liderada por Luis Alfonso Lizárraga Lizárraga, uno de los hijos de Don Cruz Lizárraga.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mxtBbvgtIMG_kal6T6-tPXQZLmKx98H3U&si=4IvaGDygynehdY8r',
    price: 51,
    coverImg: 'elRecodo.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Banda_El_Recodo',
    youtube: 'https://www.youtube.com/channel/UCtTZG7QjiCvyy0hzvEv0DlQ',
    facebook: 'https://www.facebook.com/bandaelrecodo',
    instagram: 'https://www.instagram.com/elrecodooficial/',
    twitter: 'https://twitter.com/ElRecodoOficial',
    spotify:
      'https://open.spotify.com/artist/6AcOTCYBMvjKYy4zms0kaC?si=5kU60umHQVuZdQ-g4f4LSg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/1255983',
    apple:
      'https://music.apple.com/mx/artist/banda-el-recodo-de-cruz-liz%C3%A1rraga/64615792',
    soundcloud: 'https://soundcloud.com/banda-el-recodo',
    tidal: 'https://listen.tidal.com/artist/3679413',
    wiki: 'https://es.wikipedia.org/wiki/Banda_El_Recodo',
    ticketmaster:
      'https://www.ticketmaster.com/banda-el-recodo-tickets/artist/836505',
    tiktok: 'https://www.tiktok.com/@elrecodooficial?lang=es',
    napster: 'https://web.napster.com/artist/banda-el-recodo',
  },
]
export default CardData13
